export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Datetime: { input: any; output: any; }
  EntityId: { input: any; output: any; }
  StringOrInt: { input: any; output: any; }
};

export type AimExegraphicPercentage = {
  __typename?: 'AIMExegraphicPercentage';
  exegraphicId?: Maybe<Scalars['EntityId']['output']>;
  exegraphicName?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type AbmBandCount = {
  __typename?: 'AbmBandCount';
  abmBandCount?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  targetSourceName?: Maybe<Scalars['String']['output']>;
};

export type Account = {
  __typename?: 'Account';
  company?: Maybe<Company>;
  customExegraphicLensRecords?: Maybe<Array<Maybe<CustomExegraphicLensRecord>>>;
  id: Scalars['EntityId']['output'];
  prospectCount?: Maybe<Scalars['Int']['output']>;
  prospects?: Maybe<Array<Maybe<Prospect>>>;
  recentProspectEvents?: Maybe<Array<Maybe<ProspectEvent>>>;
};


export type AccountProspectCountArgs = {
  filterItems?: InputMaybe<Array<InputMaybe<FilterItem>>>;
  linkOperator?: InputMaybe<Scalars['String']['input']>;
};


export type AccountProspectsArgs = {
  filterItems?: InputMaybe<Array<InputMaybe<FilterItem>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  linkOperator?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountRecentProspectEventsArgs = {
  filterItems?: InputMaybe<Array<InputMaybe<FilterItem>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  linkOperator?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountCsvExport = {
  __typename?: 'AccountCsvExport';
  id: Scalars['EntityId']['output'];
  includePrevious?: Maybe<Scalars['Boolean']['output']>;
  requestedCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<AccountCsvExportStatus>;
};

export enum AccountCsvExportStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type AccountCustomExegraphicLensChange = {
  __typename?: 'AccountCustomExegraphicLensChange';
  displayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previousValue?: Maybe<ExegraphicLensValue>;
  value?: Maybe<ExegraphicLensValue>;
};

export type AccountExegraphicChange = {
  __typename?: 'AccountExegraphicChange';
  account: Account;
  accountCustomExegraphicLensChange?: Maybe<AccountCustomExegraphicLensChange>;
  exegraphicLens?: Maybe<ExegraphicLens>;
  id: Scalars['String']['output'];
  idealCustomerProfiles: Array<Maybe<IdealCustomerProfile>>;
};

export type AccountExegraphicChangesPayload = {
  __typename?: 'AccountExegraphicChangesPayload';
  accountExegraphicChanges?: Maybe<Array<Maybe<AccountExegraphicChange>>>;
  status: ResponseStatus;
};

export type AccountExegraphicChangesTotalPayload = {
  __typename?: 'AccountExegraphicChangesTotalPayload';
  accountExegraphicChangesTotal: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type AccountExport = {
  __typename?: 'AccountExport';
  id: Scalars['EntityId']['output'];
  requestedCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ExportStatus>;
};

export type AccountFilter = {
  __typename?: 'AccountFilter';
  countries?: Maybe<StringOption>;
  exegraphicFilters?: Maybe<Array<Maybe<ExegraphicFilter>>>;
  headcountBins?: Maybe<Array<Maybe<HeadcountBin>>>;
  headcountRange?: Maybe<RangeOption>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  revenueRange?: Maybe<RangeOption>;
  states?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AccountIcpScoreExportCounts = {
  __typename?: 'AccountIcpScoreExportCounts';
  csvExportCount?: Maybe<Scalars['Int']['output']>;
  hubspotExportCount?: Maybe<Scalars['Int']['output']>;
  salesforceExportCount?: Maybe<Scalars['Int']['output']>;
  salesforceSandboxExportCount?: Maybe<Scalars['Int']['output']>;
};

export enum AccountIcpScoreSortableFields {
  AbsoluteScoreDelta = 'ABSOLUTE_SCORE_DELTA',
  SortScore = 'SORT_SCORE',
  StarScore = 'STAR_SCORE',
  TunedScore = 'TUNED_SCORE'
}

export type AccountSuppressionList = {
  __typename?: 'AccountSuppressionList';
  id: Scalars['EntityId']['output'];
  status?: Maybe<SuppressionListStatus>;
  uploadedFile?: Maybe<UploadedFile>;
};

export type ActiveAicpCountPayload = {
  __typename?: 'ActiveAicpCountPayload';
  count?: Maybe<Scalars['Int']['output']>;
  status: ResponseStatus;
};

export type AddComparisonListPayload = {
  __typename?: 'AddComparisonListPayload';
  idealCustomerProfile?: Maybe<IdealCustomerProfile>;
  status: ResponseStatus;
};

export type AggregateExegraphicLens = {
  __typename?: 'AggregateExegraphicLens';
  categoryDescription?: Maybe<Scalars['String']['output']>;
  categoryName?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  highCount?: Maybe<Scalars['Int']['output']>;
  lensType: ExegraphicLensType;
  lowCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  value?: Maybe<AggregateExegraphicLensValue>;
};

export enum AggregateExegraphicLensValue {
  High = 'HIGH',
  Low = 'LOW',
  Mixed = 'MIXED',
  Unknown = 'UNKNOWN'
}

export enum AggregationMethod {
  Count = 'COUNT',
  Sum = 'SUM'
}

export type AicpAccountCount = {
  __typename?: 'AicpAccountCount';
  accountCount: Scalars['Int']['output'];
  aicp: IdealCustomerProfile;
};

export type AicpCompanyDistributionsPayload = {
  __typename?: 'AicpCompanyDistributionsPayload';
  headcountDistributions?: Maybe<Array<Maybe<Distribution>>>;
  industryDistributions?: Maybe<Array<Maybe<Distribution>>>;
  status: ResponseStatus;
  targetSourceDistributions?: Maybe<Array<Maybe<AicpTargetSourceDistributions>>>;
};

export type AicpTargetSourceDistributions = {
  __typename?: 'AicpTargetSourceDistributions';
  headcountDistributions?: Maybe<Array<Maybe<Distribution>>>;
  industryDistributions?: Maybe<Array<Maybe<Distribution>>>;
  targetSourceId: Scalars['EntityId']['output'];
};

export type AimTrackingHistoryEntry = {
  __typename?: 'AimTrackingHistoryEntry';
  aimPercentage?: Maybe<Scalars['Float']['output']>;
  exegraphicPercentages?: Maybe<Array<Maybe<AimExegraphicPercentage>>>;
  label?: Maybe<Scalars['String']['output']>;
};

export type AllEntityExportTargetTypes = CsvExportTarget | HubspotExportTarget | SalesforceExportTarget | SalesforceSandboxAccountExportTarget;

export type AssignedAccount = {
  __typename?: 'AssignedAccount';
  account?: Maybe<Account>;
  bestIcpScore?: Maybe<Scalars['Float']['output']>;
  icpsSuppressedIn?: Maybe<Array<Maybe<IdealCustomerProfile>>>;
  idealCustomerProfiles?: Maybe<Array<Maybe<IdealCustomerProfile>>>;
  isTargetSource?: Maybe<Scalars['Boolean']['output']>;
};

export type AssignedAccountTotalPayload = {
  __typename?: 'AssignedAccountTotalPayload';
  assignedAccountTotal: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type AssignedAccountsPayload = {
  __typename?: 'AssignedAccountsPayload';
  assignedAccounts?: Maybe<Array<Maybe<AssignedAccount>>>;
  status: ResponseStatus;
};

export type AssignedSdrUser = {
  __typename?: 'AssignedSdrUser';
  user?: Maybe<User>;
};

export type AsyncOperation = {
  __typename?: 'AsyncOperation';
  id: Scalars['String']['output'];
  status?: Maybe<AsyncOperationStatus>;
};

export type AsyncOperationPayload = {
  __typename?: 'AsyncOperationPayload';
  asyncOperation?: Maybe<AsyncOperation>;
  status?: Maybe<ResponseStatus>;
};

export enum AsyncOperationStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Running = 'RUNNING'
}

export type Auth0LoginPayload = {
  __typename?: 'Auth0LoginPayload';
  status: ResponseStatus;
  user?: Maybe<User>;
};

export type BestCustomerSource = {
  __typename?: 'BestCustomerSource';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['EntityId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type: CompanySourceType;
  uploadedFile?: Maybe<UploadedFile>;
};

export type BullseyeCompany = {
  __typename?: 'BullseyeCompany';
  domain: Scalars['String']['output'];
  isAbm?: Maybe<Scalars['Boolean']['output']>;
  isSeed: Scalars['Boolean']['output'];
  starBandId: Scalars['Int']['output'];
  starScore: Scalars['Float']['output'];
};

export type BullseyeScoringBands = {
  __typename?: 'BullseyeScoringBands';
  abmBandCounts?: Maybe<Array<Maybe<AbmBandCount>>>;
  lookalikeBandCounts?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  lookalikeCompanies?: Maybe<Array<Maybe<BullseyeCompany>>>;
  seedCompanies?: Maybe<Array<Maybe<BullseyeCompany>>>;
  targetCompanies?: Maybe<Array<Maybe<TargetBullseyeCompanies>>>;
};

export type BullseyeVisualizationCompaniesPayload = {
  __typename?: 'BullseyeVisualizationCompaniesPayload';
  bullseyeScoringBands?: Maybe<BullseyeScoringBands>;
  jobStatus?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type CachedSignal = {
  __typename?: 'CachedSignal';
  clientOrganizationId: Scalars['EntityId']['output'];
  id: Scalars['EntityId']['output'];
  signal: Signal;
};

export type Category = {
  __typename?: 'Category';
  fieldOptions?: Maybe<Array<Maybe<SalesforceObjectField>>>;
  type: SalesforceObjectName;
};

export type CategoryFieldOptionsPayload = {
  __typename?: 'CategoryFieldOptionsPayload';
  categories?: Maybe<Array<Maybe<Category>>>;
  status?: Maybe<ResponseStatus>;
};

export type ChatMessage = {
  message?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type CitiesPayload = {
  __typename?: 'CitiesPayload';
  cities?: Maybe<Array<Maybe<City>>>;
  status: ResponseStatus;
};

export type City = {
  __typename?: 'City';
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  subdivision1?: Maybe<GeoSubdivision1>;
};

export type ClientIntegration = {
  __typename?: 'ClientIntegration';
  connectedAt: Scalars['Datetime']['output'];
  connectionType: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ClientIntegrationsPayload = {
  __typename?: 'ClientIntegrationsPayload';
  clientIntegrations?: Maybe<Array<Maybe<ClientIntegration>>>;
  status: ResponseStatus;
};

export type ClientOrganization = {
  __typename?: 'ClientOrganization';
  accountSuppressionLists?: Maybe<Array<Maybe<AccountSuppressionList>>>;
  activeCustomLenses?: Maybe<Array<Maybe<CustomExegraphicLens>>>;
  contractDetails?: Maybe<ContractDetails>;
  customExegraphicLens?: Maybe<CustomExegraphicLens>;
  customExegraphicLenses?: Maybe<Array<Maybe<CustomExegraphicLens>>>;
  downloadCounts?: Maybe<DownloadCounts>;
  hasContextProfileAnswers?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['EntityId']['output'];
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  isMfaEnabled?: Maybe<Scalars['Boolean']['output']>;
  isSsoEnabled?: Maybe<Scalars['Boolean']['output']>;
  latestExegraphicChangeAccountCsvExport?: Maybe<AccountExport>;
  name?: Maybe<Scalars['String']['output']>;
  notificationRules?: Maybe<Array<Maybe<NotificationRule>>>;
  salesPerson?: Maybe<SalesPerson>;
  salesforceAccountId?: Maybe<Scalars['EntityId']['output']>;
};


export type ClientOrganizationCustomExegraphicLensArgs = {
  lensId: Scalars['EntityId']['input'];
};

export type ClientOrganizationCachedSignalsPayload = {
  __typename?: 'ClientOrganizationCachedSignalsPayload';
  cachedSignals?: Maybe<Array<Maybe<CachedSignal>>>;
  status: ResponseStatus;
};

export type ClientOrganizationContextProfilePayload = {
  __typename?: 'ClientOrganizationContextProfilePayload';
  questionsAndAnswers?: Maybe<Array<Maybe<QuestionAndAnswer>>>;
  status?: Maybe<ResponseStatus>;
};

export type ClientOrganizationInput = {
  clientOrganizationId: Scalars['EntityId']['input'];
};

export type ClientOrganizationPayload = {
  __typename?: 'ClientOrganizationPayload';
  clientOrganization?: Maybe<ClientOrganization>;
  status?: Maybe<ResponseStatus>;
};

export type ClientOrganizationSavedSignalsPayload = {
  __typename?: 'ClientOrganizationSavedSignalsPayload';
  savedSignals?: Maybe<Array<Maybe<SavedSignal>>>;
  status: ResponseStatus;
};

export type ClientOrganizationsCountPayload = {
  __typename?: 'ClientOrganizationsCountPayload';
  count: Scalars['Int']['output'];
  status?: Maybe<ResponseStatus>;
};

export type ClientOrganizationsPayload = {
  __typename?: 'ClientOrganizationsPayload';
  clientOrganizations?: Maybe<Array<Maybe<ClientOrganization>>>;
  status?: Maybe<ResponseStatus>;
};

export type ClientPlatformIntegration = {
  __typename?: 'ClientPlatformIntegration';
  integration?: Maybe<Integration>;
  status: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  updatedBy?: Maybe<User>;
};

export enum ClientPlatformIntegrationConnectionType {
  Hubspot = 'HUBSPOT',
  Outreach = 'OUTREACH',
  Salesforce = 'SALESFORCE',
  SalesforceSandbox = 'SALESFORCE_SANDBOX',
  Zoominfo = 'ZOOMINFO'
}

export type ClientPlatformIntegrationLeadTransferCompleteDetail = {
  __typename?: 'ClientPlatformIntegrationLeadTransferCompleteDetail';
  successfulLeadIds?: Maybe<Array<Maybe<Scalars['EntityId']['output']>>>;
};

export type ClientPlatformIntegrationsPayload = {
  __typename?: 'ClientPlatformIntegrationsPayload';
  clientPlatformIntegrations?: Maybe<Array<Maybe<ClientPlatformIntegration>>>;
  status?: Maybe<ResponseStatus>;
};

export enum CollectionType {
  CompanySummary = 'COMPANY_SUMMARY',
  Employee = 'EMPLOYEE',
  JobPosting = 'JOB_POSTING',
  News = 'NEWS',
  Technographics = 'TECHNOGRAPHICS',
  WebScrape = 'WEB_SCRAPE'
}

export type CompaniesWithMatchingPlatformAccountsPayload = {
  __typename?: 'CompaniesWithMatchingPlatformAccountsPayload';
  companiesWithMatchingPlatformAccounts?: Maybe<Array<Maybe<CompanyWithMatchingPlatformAccounts>>>;
  status: ResponseStatus;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  exegraphicLenses?: Maybe<Array<Maybe<ExegraphicLens>>>;
  firmographics?: Maybe<Firmographics>;
  name?: Maybe<Scalars['String']['output']>;
  partnerData?: Maybe<CompanyPartnerData>;
  registeredDomain?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type CompanyCsvExport = {
  __typename?: 'CompanyCsvExport';
  id: Scalars['EntityId']['output'];
  requestedCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ExportStatus>;
};

export type CompanyCsvExportStatusPayload = {
  __typename?: 'CompanyCsvExportStatusPayload';
  latestCompanyCsvExport?: Maybe<CompanyCsvExport>;
  status: ResponseStatus;
};

export type CompanyCsvForExportPayload = {
  __typename?: 'CompanyCsvForExportPayload';
  friendlyFileName?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
  temporaryUrl?: Maybe<Scalars['String']['output']>;
};

export type CompanyDownloadCountPayload = {
  __typename?: 'CompanyDownloadCountPayload';
  count?: Maybe<Scalars['Int']['output']>;
  status: ResponseStatus;
};

export type CompanyExegraphicLensesPayload = {
  __typename?: 'CompanyExegraphicLensesPayload';
  categoryOptions: Array<Maybe<ExegraphicLensCategory>>;
  status: ResponseStatus;
};

export type CompanyFilterOptionsPayload = {
  __typename?: 'CompanyFilterOptionsPayload';
  countries?: Maybe<Array<Maybe<DropdownOption>>>;
  industries?: Maybe<Array<Maybe<DropdownOption>>>;
  ric5industries?: Maybe<Array<Maybe<Industry>>>;
  stateOptions?: Maybe<Array<Maybe<DropdownOption>>>;
  status: ResponseStatus;
};

export type CompanyFiltersInput = {
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customExegraphicSignals?: InputMaybe<Array<InputMaybe<SelectedCustomExegraphicLensInput>>>;
  customSignalLinkOperator?: InputMaybe<FilterLinkOperator>;
  dnbHeadcount?: InputMaybe<IntRangeInput>;
  dnbIndustries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dnbRevenue?: InputMaybe<IntRangeInput>;
  excludedCustomExegraphicSignalLinkOperator?: InputMaybe<FilterLinkOperator>;
  excludedCustomExegraphicSignals?: InputMaybe<Array<InputMaybe<SelectedCustomExegraphicLensInput>>>;
  exegraphicSignals?: InputMaybe<Array<InputMaybe<SelectedExegraphicLensInput>>>;
  exegraphicSignalsFilterOperator?: InputMaybe<FilterLinkOperator>;
  headcountBins?: InputMaybe<Array<InputMaybe<IntRangeInput>>>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  revenueBins?: InputMaybe<Array<InputMaybe<IntRangeInput>>>;
  searchString?: InputMaybe<Scalars['String']['input']>;
  selectedCompanyRegisteredDomains?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CompanyList = {
  __typename?: 'CompanyList';
  createdAt: Scalars['Datetime']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyListOrCompanySource = BestCustomerSource | CompanyList;

export enum CompanyListType {
  Basic = 'BASIC',
  SalesforceAccount = 'SALESFORCE_ACCOUNT'
}

export type CompanyLookupCountPayload = {
  __typename?: 'CompanyLookupCountPayload';
  companies?: Maybe<Scalars['Int']['output']>;
  status: ResponseStatus;
};

export type CompanyLookupPayload = {
  __typename?: 'CompanyLookupPayload';
  companyLookupCompanies?: Maybe<Array<Maybe<CompanySnapshot>>>;
  status: ResponseStatus;
};

export type CompanyNameTabularDataFileInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  uploadedAt?: InputMaybe<Scalars['Datetime']['input']>;
  url: Scalars['String']['input'];
};

export type CompanyOrCompanyWithCustomExegraphicLensRecords = Company | CompanyWithCustomExegraphicLensRecords;

export type CompanyPartnerData = {
  __typename?: 'CompanyPartnerData';
  dnb?: Maybe<DnbPartnerData>;
};

export type CompanyPayload = {
  __typename?: 'CompanyPayload';
  company?: Maybe<Company>;
  status: ResponseStatus;
};

export type CompanySnapshot = {
  __typename?: 'CompanySnapshot';
  customSignals?: Maybe<Array<Maybe<SnapshotSignal>>>;
  firmographics?: Maybe<CompanySnapshotFirmographics>;
  name?: Maybe<Scalars['String']['output']>;
  partnerData?: Maybe<CompanySnapshotPartnerData>;
  registeredDomain?: Maybe<Scalars['String']['output']>;
  signals?: Maybe<Array<Maybe<SnapshotSignal>>>;
};

export type CompanySnapshotFirmographics = {
  __typename?: 'CompanySnapshotFirmographics';
  country?: Maybe<Scalars['String']['output']>;
  headcountRange?: Maybe<RangeOption>;
  industry?: Maybe<Scalars['String']['output']>;
  revenueRange?: Maybe<RangeOption>;
  state?: Maybe<Scalars['String']['output']>;
  subindustry?: Maybe<Scalars['String']['output']>;
};

export type CompanySnapshotPartnerData = {
  __typename?: 'CompanySnapshotPartnerData';
  dnb?: Maybe<SnapshotDnbPartnerData>;
};

export type CompanySource = {
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['EntityId']['output'];
  status?: Maybe<CompanySourceStatus>;
  type: CompanySourceType;
};

export enum CompanySourceStatus {
  Active = 'ACTIVE',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Removed = 'REMOVED',
  Started = 'STARTED'
}

export enum CompanySourceType {
  Csv = 'CSV'
}

export type CompanyUrlLookupInput = {
  emailAddress: Scalars['String']['input'];
  file: CompanyNameTabularDataFileInput;
};

export type CompanyUrlLookupPayload = {
  __typename?: 'CompanyUrlLookupPayload';
  status: ResponseStatus;
};

export type CompanyWithCustomExegraphicLensRecords = {
  __typename?: 'CompanyWithCustomExegraphicLensRecords';
  company?: Maybe<Company>;
  customExegraphicLensRecords?: Maybe<Array<Maybe<CustomExegraphicLensRecord>>>;
};

export type CompanyWithMatchingPlatformAccounts = {
  __typename?: 'CompanyWithMatchingPlatformAccounts';
  company?: Maybe<Company>;
  platformAccountMatches?: Maybe<Array<Maybe<PlatformAccountMatch>>>;
};

export type ConfigurationUrlPayload = {
  __typename?: 'ConfigurationUrlPayload';
  configurationUrl?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export enum ContactJobFunction {
  Accounting = 'ACCOUNTING',
  Administrator = 'ADMINISTRATOR',
  Analyst = 'ANALYST',
  Communications = 'COMMUNICATIONS',
  Compliance = 'COMPLIANCE',
  Creative = 'CREATIVE',
  CustomerCare = 'CUSTOMER_CARE',
  Engineering = 'ENGINEERING',
  Finance = 'FINANCE',
  Hr = 'HR',
  It = 'IT',
  Legal = 'LEGAL',
  Maintenance = 'MAINTENANCE',
  Marketing = 'MARKETING',
  Medical = 'MEDICAL',
  Operations = 'OPERATIONS',
  Product = 'PRODUCT',
  Project = 'PROJECT',
  RetailHospitality = 'RETAIL_HOSPITALITY',
  Sales = 'SALES',
  Software = 'SOFTWARE',
  SupplyChain = 'SUPPLY_CHAIN',
  Training = 'TRAINING'
}

export enum ContactSeniorityLevel {
  Chief = 'CHIEF',
  Director = 'DIRECTOR',
  Manager = 'MANAGER',
  Owner = 'OWNER',
  VicePresident = 'VICE_PRESIDENT'
}

export enum ContactSource {
  Internal = 'INTERNAL',
  Zoominfo = 'ZOOMINFO'
}

export type ContextProfileAnswerInput = {
  answerText: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
};

export type ContractDetails = {
  __typename?: 'ContractDetails';
  aicpLimit?: Maybe<Scalars['Int']['output']>;
  companyDownloadLimit?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Datetime']['output']>;
  partnerDataSource?: Maybe<Array<Maybe<PartnerDataSource>>>;
  startDate?: Maybe<Scalars['Datetime']['output']>;
  userLimit?: Maybe<Scalars['Int']['output']>;
};

export type Country = {
  __typename?: 'Country';
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateIcpPayload = {
  __typename?: 'CreateIcpPayload';
  idealCustomerProfile?: Maybe<IdealCustomerProfile>;
  status: ResponseStatus;
};

export type CreateIntegrationInput = {
  clientOrganizationId: Scalars['EntityId']['input'];
  hasDataCollectionEnabled: Scalars['Boolean']['input'];
};

export type CsvCompanySource = CompanySource & {
  __typename?: 'CsvCompanySource';
  createdAt: Scalars['Datetime']['output'];
  id: Scalars['EntityId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanySourceStatus>;
  type: CompanySourceType;
  uploadedFile?: Maybe<UploadedFile>;
};

export type CsvExportTarget = {
  __typename?: 'CsvExportTarget';
  entityType?: Maybe<ExportEntityType>;
};

export type CustomExegraphicLens = {
  __typename?: 'CustomExegraphicLens';
  components?: Maybe<Array<Maybe<OpenSearchIndexSignalComponent>>>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  isMulticomponent?: Maybe<Scalars['Boolean']['output']>;
  lensAssembly?: Maybe<LensAssembly>;
  name: Scalars['String']['output'];
  referencedByIcpScoreTuningConfigs?: Maybe<Array<Maybe<IcpScoreTuningConfig>>>;
  schemaFile?: Maybe<UploadedFile>;
  signalDsl?: Maybe<SignalDsl>;
  status?: Maybe<CustomExegraphicLensStatus>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  uploadedFile?: Maybe<UploadedFile>;
  uploadedJsonFile?: Maybe<UploadedFile>;
};

export type CustomExegraphicLensPayload = {
  __typename?: 'CustomExegraphicLensPayload';
  customExegraphicLens?: Maybe<CustomExegraphicLens>;
  status: ResponseStatus;
};

export type CustomExegraphicLensRecord = {
  __typename?: 'CustomExegraphicLensRecord';
  lensDescription: Scalars['String']['output'];
  lensName: Scalars['String']['output'];
  value: ExegraphicLensValue;
};

export enum CustomExegraphicLensStatus {
  Active = 'ACTIVE',
  FailedToProcess = 'FAILED_TO_PROCESS',
  Processing = 'PROCESSING',
  Removed = 'REMOVED'
}

export type CustomExegraphicLensTuning = {
  __typename?: 'CustomExegraphicLensTuning';
  customExegraphicLensId: Scalars['EntityId']['output'];
  effect: TuningEffect;
  qualifier: ExegraphicLensValue;
  strength: Scalars['Int']['output'];
};

export type CustomExegraphicLensTuningInput = {
  customExegraphicLensId: Scalars['EntityId']['input'];
  effect: TuningEffect;
  qualifier: ExegraphicLensValue;
  strength: Scalars['Int']['input'];
};

export type CustomExegraphicLensUrlsPayload = {
  __typename?: 'CustomExegraphicLensUrlsPayload';
  fileUrl?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type CustomExegraphicLensesToExportInput = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};

export type DnbIndustriesPayload = {
  __typename?: 'DNBIndustriesPayload';
  dnbIndustries?: Maybe<Array<Maybe<Industry>>>;
  status: ResponseStatus;
};

export type DnbPartnerData = {
  __typename?: 'DNBPartnerData';
  headcount?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  sicFourIndustry?: Maybe<Industry>;
  sicTwoIndustry?: Maybe<Industry>;
};

export enum DataType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Enumeration = 'ENUMERATION',
  Float = 'FLOAT',
  Int = 'INT',
  String = 'STRING',
  Unknown = 'UNKNOWN'
}

export enum DateWindow {
  FortyFiveDays = 'FORTY_FIVE_DAYS',
  NinetyDays = 'NINETY_DAYS',
  OneYear = 'ONE_YEAR',
  SixtyDays = 'SIXTY_DAYS',
  SixMonths = 'SIX_MONTHS',
  TwoYears = 'TWO_YEARS'
}

export type DeleteCachedSignalPayload = {
  __typename?: 'DeleteCachedSignalPayload';
  status: ResponseStatus;
};

export type Distribution = {
  __typename?: 'Distribution';
  bucketName?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DownloadCounts = {
  __typename?: 'DownloadCounts';
  totalCompanyDownloadCount?: Maybe<Scalars['Int']['output']>;
  uniqueCompanyDownloadCount?: Maybe<Scalars['Int']['output']>;
};

export type DropdownIntLabelOption = {
  __typename?: 'DropdownIntLabelOption';
  label: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type DropdownOption = {
  __typename?: 'DropdownOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum EmailConfidence {
  Risky = 'RISKY',
  Valid = 'VALID'
}

export type EnrichedContact = {
  __typename?: 'EnrichedContact';
  email?: Maybe<Scalars['String']['output']>;
  emailConfidence?: Maybe<EmailConfidence>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  linkedInUrl?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EntityExport = {
  __typename?: 'EntityExport';
  actualCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  createdByUser?: Maybe<User>;
  exportSource?: Maybe<IdealCustomerProfile>;
  exportTarget?: Maybe<AllEntityExportTargetTypes>;
  exportTargetType?: Maybe<ExportTargetType>;
  failedCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['EntityId']['output'];
  requestedCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ExportStatus>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type EntityExportFailuresCountPayload = {
  __typename?: 'EntityExportFailuresCountPayload';
  entityExportFailuresCount?: Maybe<Scalars['Int']['output']>;
  status: ResponseStatus;
};

export type EntityExportFailuresPayload = {
  __typename?: 'EntityExportFailuresPayload';
  entityExportFailures?: Maybe<Array<Maybe<EntityToExportData>>>;
  exportTargetType?: Maybe<ExportTargetType>;
  status: ResponseStatus;
};

export type EntityToExport = {
  __typename?: 'EntityToExport';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  entityId?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<ExportEntityType>;
  errorCode?: Maybe<EntityToExportErrorCode>;
  id: Scalars['EntityId']['output'];
  platformErrorMessage?: Maybe<Scalars['String']['output']>;
  status: ExportStatus;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type EntityToExportData = {
  __typename?: 'EntityToExportData';
  company?: Maybe<Company>;
  entityToExport?: Maybe<EntityToExport>;
  platformAccountMatches?: Maybe<Array<Maybe<PlatformAccountMatch>>>;
};

export enum EntityToExportErrorCode {
  AccountCompanyDeleted = 'ACCOUNT_COMPANY_DELETED',
  AccountDuplicateRecord = 'ACCOUNT_DUPLICATE_RECORD',
  CustomFieldInvalidValue = 'CUSTOM_FIELD_INVALID_VALUE',
  DomainMismatch = 'DOMAIN_MISMATCH',
  InvalidFieldDataFormat = 'INVALID_FIELD_DATA_FORMAT',
  PicklistInvalidValue = 'PICKLIST_INVALID_VALUE',
  StorageExceeded = 'STORAGE_EXCEEDED',
  UnableToWriteRecord = 'UNABLE_TO_WRITE_RECORD',
  UnknownFailure = 'UNKNOWN_FAILURE'
}

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type EventLogEntriesPayload = {
  __typename?: 'EventLogEntriesPayload';
  eventLogEntries?: Maybe<Array<Maybe<EventLogEntry>>>;
  status?: Maybe<ResponseStatus>;
};

export type EventLogEntry = {
  __typename?: 'EventLogEntry';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  detail: ClientPlatformIntegrationLeadTransferCompleteDetail;
  eventType?: Maybe<Scalars['String']['output']>;
};

export type Exegraphic = {
  __typename?: 'Exegraphic';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ExegraphicFilter = {
  __typename?: 'ExegraphicFilter';
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type ExegraphicFilterInput = {
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExegraphicLenNameAndDescription = {
  __typename?: 'ExegraphicLenNameAndDescription';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ExegraphicLens = {
  __typename?: 'ExegraphicLens';
  categoryDescription: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  lensType: ExegraphicLensType;
  name: Scalars['String']['output'];
  previousValue?: Maybe<ExegraphicLensValue>;
  value?: Maybe<ExegraphicLensValue>;
};

export type ExegraphicLensCategory = {
  __typename?: 'ExegraphicLensCategory';
  description: Scalars['String']['output'];
  exegraphicLensNames: Array<Maybe<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
};

export type ExegraphicLensNameAndDescription = {
  __typename?: 'ExegraphicLensNameAndDescription';
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
};

export type ExegraphicLensPayload = {
  __typename?: 'ExegraphicLensPayload';
  lenses?: Maybe<Array<Maybe<ExegraphicLensNameAndDescription>>>;
  status: ResponseStatus;
};

export type ExegraphicLensTuning = {
  __typename?: 'ExegraphicLensTuning';
  effect: TuningEffect;
  exegraphicLensName: Scalars['String']['output'];
  qualifier: ExegraphicLensValue;
  strength: Scalars['Int']['output'];
};

export type ExegraphicLensTuningInput = {
  effect: TuningEffect;
  exegraphicLensName: Scalars['String']['input'];
  qualifier: ExegraphicLensValue;
  strength: Scalars['Int']['input'];
};

export enum ExegraphicLensType {
  Event = 'EVENT',
  Static = 'STATIC'
}

export enum ExegraphicLensValue {
  High = 'HIGH',
  Low = 'LOW',
  Mixed = 'MIXED',
  Unknown = 'UNKNOWN'
}

export enum ExportAction {
  Insert = 'INSERT',
  Update = 'UPDATE',
  UpdateAndInsert = 'UPDATE_AND_INSERT'
}

export type ExportConfig = {
  __typename?: 'ExportConfig';
  entityName?: Maybe<ExportableEntityName>;
  fieldMappings?: Maybe<Array<Maybe<FieldMapping>>>;
  platformObjectName?: Maybe<Scalars['String']['output']>;
};

export enum ExportEntityType {
  Account = 'ACCOUNT',
  AccountExegraphicLensChange = 'ACCOUNT_EXEGRAPHIC_LENS_CHANGE',
  Company = 'COMPANY'
}

export type ExportFieldOption = {
  __typename?: 'ExportFieldOption';
  dataTypes: Array<Maybe<DataType>>;
  internalField: Scalars['String']['output'];
  isDefaultDisplayField: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export enum ExportMethod {
  Csv = 'CSV',
  Hubspot = 'HUBSPOT',
  Outreach = 'OUTREACH',
  Salesforce = 'SALESFORCE',
  SalesforceSandbox = 'SALESFORCE_SANDBOX'
}

export enum ExportStatus {
  Completed = 'COMPLETED',
  Dismissed = 'DISMISSED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Started = 'STARTED'
}

export enum ExportTargetType {
  Csv = 'CSV',
  Hubspot = 'HUBSPOT',
  Salesforce = 'SALESFORCE',
  SalesforceSandbox = 'SALESFORCE_SANDBOX'
}

export enum ExportableEntityName {
  Company = 'COMPANY',
  Contact = 'CONTACT'
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type FeatureFlagsPayload = {
  __typename?: 'FeatureFlagsPayload';
  featureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  status?: Maybe<ResponseStatus>;
};

export type FieldMapping = {
  __typename?: 'FieldMapping';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  internalField?: Maybe<Scalars['String']['output']>;
  platformObjectFieldExternalId?: Maybe<Scalars['String']['output']>;
  staticValue?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type FieldMappingInput = {
  internalField?: InputMaybe<Scalars['String']['input']>;
  platformObjectFieldExternalId?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type FieldMatcher = {
  __typename?: 'FieldMatcher';
  platformObjectFieldExternalId?: Maybe<Scalars['String']['output']>;
  platformObjectName?: Maybe<SalesforceObjectName>;
  staticValueToMatchOn?: Maybe<Scalars['String']['output']>;
};

export type FileInput = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  temporaryUrl: Scalars['String']['input'];
};

export type FilterItem = {
  columnField: Scalars['String']['input'];
  operatorValue: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum FilterLinkOperator {
  And = 'AND',
  Or = 'OR'
}

export type Firmographics = {
  __typename?: 'Firmographics';
  headcountRange?: Maybe<RangeOption>;
  industries?: Maybe<Array<Maybe<Industry>>>;
  revenue?: Maybe<Scalars['Float']['output']>;
  revenueRange?: Maybe<RangeOption>;
};

export type GenerateCachedSignalPayload = {
  __typename?: 'GenerateCachedSignalPayload';
  status: ResponseStatus;
};

export type GenerateSignalNamePayload = {
  __typename?: 'GenerateSignalNamePayload';
  status?: Maybe<ResponseStatus>;
  suggestedSignalName?: Maybe<Scalars['String']['output']>;
};

export type GeoSubdivision1 = {
  __typename?: 'GeoSubdivision1';
  country?: Maybe<Country>;
  id: Scalars['EntityId']['output'];
  isoCode: Scalars['String']['output'];
  metroAreas?: Maybe<Array<Maybe<MetroArea>>>;
  name: Scalars['String']['output'];
  postalCodePrefix3s?: Maybe<Array<Maybe<PostalCodePrefix3>>>;
};

export type GeoSubdivision1Payload = {
  __typename?: 'GeoSubdivision1Payload';
  geoSubdivision1?: Maybe<GeoSubdivision1>;
  status: ResponseStatus;
};

export type GeoSubdivision1sPayload = {
  __typename?: 'GeoSubdivision1sPayload';
  geoSubdivision1s?: Maybe<Array<Maybe<GeoSubdivision1>>>;
  status: ResponseStatus;
};

export type GeographicRegion = {
  __typename?: 'GeographicRegion';
  abbrev?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  type: GeographicRegionType;
};

export enum GeographicRegionType {
  City = 'CITY',
  County = 'COUNTY',
  State = 'STATE'
}

export type GeographicRegionsPayload = {
  __typename?: 'GeographicRegionsPayload';
  geographicRegions?: Maybe<Array<Maybe<GeographicRegion>>>;
  status: ResponseStatus;
};

export type GetEventLogInput = {
  platformIntegrationType: Scalars['String']['input'];
};

export type HeadcountBin = {
  __typename?: 'HeadcountBin';
  bin?: Maybe<Scalars['Int']['output']>;
  range?: Maybe<RangeOption>;
};

export type HubspotClientPlatformIntegrationPayload = {
  __typename?: 'HubspotClientPlatformIntegrationPayload';
  hubspotIntegration?: Maybe<HubspotIntegration>;
  status?: Maybe<ResponseStatus>;
};

export type HubspotExportTarget = {
  __typename?: 'HubspotExportTarget';
  entityType?: Maybe<ExportEntityType>;
  exportAction?: Maybe<ExportAction>;
};

export type HubspotIntegration = {
  __typename?: 'HubspotIntegration';
  exportConfigs?: Maybe<Array<Maybe<ExportConfig>>>;
  platformObjects?: Maybe<Array<Maybe<HubspotPlatformObject>>>;
};

export type HubspotPlatformObject = {
  __typename?: 'HubspotPlatformObject';
  fields?: Maybe<Array<Maybe<HubspotPlatformObjectField>>>;
  name: Scalars['String']['output'];
};

export type HubspotPlatformObjectField = {
  __typename?: 'HubspotPlatformObjectField';
  dataType: DataType;
  externalId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<PlatformObjectFieldOption>>>;
  propertyGroupId?: Maybe<Scalars['String']['output']>;
  propertyGroupLabel?: Maybe<Scalars['String']['output']>;
};

export type HubspotProspectExportStatusPayload = {
  __typename?: 'HubspotProspectExportStatusPayload';
  prospectExport?: Maybe<ProspectExport>;
  status?: Maybe<ResponseStatus>;
};

export type IcpAccountIndustriesPayload = {
  __typename?: 'IcpAccountIndustriesPayload';
  icpAccountIndustries?: Maybe<Array<Maybe<IcpAccountIndustry>>>;
  status: ResponseStatus;
};

export type IcpAccountIndustry = {
  __typename?: 'IcpAccountIndustry';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
};

export type IcpCsvPayload = {
  __typename?: 'IcpCsvPayload';
  friendlyFileName?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
  temporaryUrl?: Maybe<Scalars['String']['output']>;
};

export type IcpFilterOptionsPayload = {
  __typename?: 'IcpFilterOptionsPayload';
  headcountOptions?: Maybe<Array<Maybe<DropdownIntLabelOption>>>;
  industryOptions?: Maybe<Array<Maybe<DropdownOption>>>;
  ric5IndustryOptions?: Maybe<Array<Maybe<Industry>>>;
  stateOptions?: Maybe<Array<Maybe<DropdownOption>>>;
  status: ResponseStatus;
  userOptions?: Maybe<Array<Maybe<DropdownOption>>>;
};

export type IcpPerformanceReportConfigPayload = {
  __typename?: 'IcpPerformanceReportConfigPayload';
  performanceReportConfig?: Maybe<PerformanceReportConfig>;
  status: ResponseStatus;
};

export type IcpPerformanceReportDataUpdateStatus = {
  __typename?: 'IcpPerformanceReportDataUpdateStatus';
  eventStatus?: Maybe<SalesforceIntegrationImportEventStatus>;
  status: ResponseStatus;
};

export type IcpPerformanceReportPayload = {
  __typename?: 'IcpPerformanceReportPayload';
  performanceReport?: Maybe<PerformanceReport>;
  status: ResponseStatus;
};

export type IcpScoreTuningConfig = {
  __typename?: 'IcpScoreTuningConfig';
  customExegraphicLensTunings?: Maybe<Array<Maybe<CustomExegraphicLensTuning>>>;
  exegraphicLensTunings?: Maybe<Array<Maybe<ExegraphicLensTuning>>>;
  icp?: Maybe<IdealCustomerProfile>;
};

export type IcpScoreTuningConfigPayload = {
  __typename?: 'IcpScoreTuningConfigPayload';
  icpScoreTuningConfig?: Maybe<IcpScoreTuningConfig>;
  status: ResponseStatus;
};

export enum IcpStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Removed = 'REMOVED',
  Updating = 'UPDATING'
}

export type IdealCustomerProfile = {
  __typename?: 'IdealCustomerProfile';
  accountFilter?: Maybe<AccountFilter>;
  accountSuppressionLists?: Maybe<Array<Maybe<AccountSuppressionList>>>;
  activeTargetSources?: Maybe<Array<Maybe<CompanySource>>>;
  assignedSdrUsers?: Maybe<Array<Maybe<AssignedSdrUser>>>;
  bestCustomerSources?: Maybe<Array<Maybe<CompanySource>>>;
  clientOrganization?: Maybe<ClientOrganization>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  createdBy?: Maybe<User>;
  customExegraphicLenses?: Maybe<Array<Maybe<AggregateExegraphicLens>>>;
  exegraphicLenses?: Maybe<Array<Maybe<AggregateExegraphicLens>>>;
  hasPreviousVersion?: Maybe<Scalars['Boolean']['output']>;
  hasValidIcpScoreTuningConfig?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['EntityId']['output'];
  isDynamic?: Maybe<Scalars['Boolean']['output']>;
  latestAccountCsvExport?: Maybe<AccountExport>;
  latestAicpNote?: Maybe<Note>;
  latestScoredTargetCsvExport?: Maybe<AccountCsvExport>;
  latestSeedList?: Maybe<CompanyListOrCompanySource>;
  latestSeedListAccountCsvExport?: Maybe<AccountCsvExport>;
  name?: Maybe<Scalars['String']['output']>;
  originalCreationDate?: Maybe<Scalars['Datetime']['output']>;
  prospectFilter?: Maybe<ProspectFilter>;
  scoredAccounts?: Maybe<Array<Maybe<ScoredAccount>>>;
  scoredAccountsTotal?: Maybe<Scalars['Float']['output']>;
  scoredComparisonListCsvExports?: Maybe<Array<Maybe<ScoredTargetCsvExport>>>;
  seedListAccountCsvExports?: Maybe<Array<Maybe<SeedListAccountCsvExport>>>;
  status?: Maybe<IcpStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  targetSources?: Maybe<Array<Maybe<CompanySource>>>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};


export type IdealCustomerProfileScoredAccountsArgs = {
  filters?: InputMaybe<ScoredAccountFiltersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AccountIcpScoreSortableFields>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type IdealCustomerProfileScoredAccountsTotalArgs = {
  filters?: InputMaybe<ScoredAccountFiltersInput>;
};

export type IdealCustomerProfilePayload = {
  __typename?: 'IdealCustomerProfilePayload';
  idealCustomerProfile?: Maybe<IdealCustomerProfile>;
  status: ResponseStatus;
};

export type IdealCustomerProfilesPayload = {
  __typename?: 'IdealCustomerProfilesPayload';
  idealCustomerProfiles?: Maybe<Array<Maybe<IdealCustomerProfile>>>;
  status: ResponseStatus;
};

export enum IncludeAccountTypeFilterOptions {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
  Only = 'ONLY'
}

export type Industry = {
  __typename?: 'Industry';
  code: Scalars['StringOrInt']['output'];
  description?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  parentCode?: Maybe<Scalars['StringOrInt']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type IndustryInput = {
  code: Scalars['StringOrInt']['input'];
  type: Scalars['String']['input'];
};

export enum IndustryType {
  Ric4 = 'RIC4',
  Ric5 = 'RIC5',
  Sic = 'SIC'
}

export type IntRange = {
  __typename?: 'IntRange';
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type IntRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type Integration = {
  __typename?: 'Integration';
  displayName: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  oauth2Config?: Maybe<Oauth2Config>;
};

export type IntegrationDeprecated = {
  __typename?: 'IntegrationDeprecated';
  createdAt: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  grantedService: Scalars['String']['output'];
};

export type IntegrationsDeprecatedPayload = {
  __typename?: 'IntegrationsDeprecatedPayload';
  integrations?: Maybe<Array<Maybe<IntegrationDeprecated>>>;
  status: ResponseStatus;
};

export type IntegrationsPayload = {
  __typename?: 'IntegrationsPayload';
  integrations?: Maybe<Array<Maybe<Integration>>>;
  status?: Maybe<ResponseStatus>;
};

export type IsIcpReadyForPerformanceReportPayload = {
  __typename?: 'IsIcpReadyForPerformanceReportPayload';
  isReady: Scalars['Boolean']['output'];
  status: ResponseStatus;
};

export type IsOpenSearchSignalComponentQueryTextValidPayload = {
  __typename?: 'IsOpenSearchSignalComponentQueryTextValidPayload';
  isValid: Scalars['Boolean']['output'];
  status: ResponseStatus;
};

export type LensAssembly = {
  __typename?: 'LensAssembly';
  signalLogic: Scalars['String']['output'];
};

export type LensBuilderSelectSignalChatResponsePayload = {
  __typename?: 'LensBuilderSelectSignalChatResponsePayload';
  response?: Maybe<Scalars['String']['output']>;
  signal?: Maybe<LensBuilderSignal>;
  status: ResponseStatus;
};

export type LensBuilderSignal = {
  __typename?: 'LensBuilderSignal';
  code?: Maybe<CollectionType>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type LensBuilderSignalChatResponsePayload = {
  __typename?: 'LensBuilderSignalChatResponsePayload';
  naturalLanguageResponse?: Maybe<Scalars['String']['output']>;
  queryTextResponse?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type LensBuilderSignalsPayload = {
  __typename?: 'LensBuilderSignalsPayload';
  signals?: Maybe<Array<Maybe<LensBuilderSignal>>>;
  status: ResponseStatus;
};

export type LlmGeneratedSignalDescriptionPayload = {
  __typename?: 'LlmGeneratedSignalDescriptionPayload';
  description?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
  title?: Maybe<Scalars['String']['output']>;
};

export type LlmGeneratedSignalDetailsPayload = {
  __typename?: 'LlmGeneratedSignalDetailsPayload';
  signal: Signal;
  status: ResponseStatus;
};

export type LogSignalQueryDslUserActionPayload = {
  __typename?: 'LogSignalQueryDslUserActionPayload';
  status: ResponseStatus;
};

export enum LogSource {
  Chat = 'CHAT',
  Explore = 'EXPLORE',
  New = 'NEW',
  Unknown = 'UNKNOWN'
}

export type LoginPayload = {
  __typename?: 'LoginPayload';
  status: ResponseStatus;
  unhashedLoginToken?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type MetroArea = {
  __typename?: 'MetroArea';
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  subdivision1?: Maybe<GeoSubdivision1>;
};

export type MetroAreasPayload = {
  __typename?: 'MetroAreasPayload';
  metroAreas?: Maybe<Array<Maybe<MetroArea>>>;
  status: ResponseStatus;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAicpNote?: Maybe<ResponsePayload>;
  addComparisonList: AddComparisonListPayload;
  addCustomExegraphicLens: ResponsePayload;
  cloneSalesforceConfiguration?: Maybe<ResponsePayload>;
  createClientOrganization: ClientOrganizationPayload;
  createCompanyUrlLookup: CompanyUrlLookupPayload;
  createCustomLens?: Maybe<ResponsePayload>;
  createIcp: CreateIcpPayload;
  createNewVersionIcp: ResponsePayload;
  createNotificationRule?: Maybe<NotificationRulePayload>;
  createSalesForceIntegration: ConfigurationUrlPayload;
  createSourceCompaniesList: SourceCompaniesListPayload;
  deleteAllCachedSignals: DeleteCachedSignalPayload;
  deleteCachedSignal: DeleteCachedSignalPayload;
  deleteNotificationRule: ResponsePayload;
  disableHubspotIntegration?: Maybe<ResponsePayload>;
  disableOutreachIntegration?: Maybe<ResponsePayload>;
  disableSalesforceIntegration?: Maybe<ResponsePayload>;
  disableSalesforceSandboxIntegration?: Maybe<ResponsePayload>;
  disableZoomInfoIntegration?: Maybe<ResponsePayload>;
  dismissEntitiesToExport: ResponsePayload;
  editCustomSignal?: Maybe<ResponsePayload>;
  enableHubspotIntegration?: Maybe<ResponsePayload>;
  enableOutreachIntegration?: Maybe<ResponsePayload>;
  enableSalesforceIntegration?: Maybe<ResponsePayload>;
  enableSalesforceSandboxIntegration?: Maybe<ResponsePayload>;
  enableZoomInfoIntegration?: Maybe<ResponsePayload>;
  generateCachedSignal?: Maybe<GenerateCachedSignalPayload>;
  getProspectsForCsvExport: ProspectsForCsvExportPayload;
  handleIcpCompaniesLookalikeApiResultError?: Maybe<UpdateIcpCompaniesLookalikeApiResultPayload>;
  inviteUserToClientOrganization: LoginPayload;
  matchPlatformAccount: ResponsePayload;
  processAccountCsvExportRequest: ResponsePayload;
  processAccountHubspotExport?: Maybe<ResponsePayload>;
  processAccountSalesforceExport?: Maybe<ResponsePayload>;
  processCompanyCsvExportRequest: ResponsePayload;
  processCompanyPlatformIntegrationExport: ResponsePayload;
  processIcpUpdatesAccountCsvExportRequest: ResponsePayload;
  processIcpUpdatesAccountSalesforceExport?: Maybe<ResponsePayload>;
  refreshAccessToken: RefreshAccessTokenPayload;
  registerUploadedFile: UploadedFilePayload;
  removeAccountSuppressionListFromIcp: ResponsePayload;
  removeComparisonListFromIcp: ResponsePayload;
  removeCustomExegraphicLens: ResponsePayload;
  removeIcp: RemoveIcpPayload;
  removeSalesForceIntegration: ResponsePayload;
  removeSalesforceConfiguration?: Maybe<ResponsePayload>;
  removeUser: ResponsePayload;
  removeUserFromClientOrg: ResponsePayload;
  requestAccountUpgrade: ResponsePayload;
  retryExportProcess: ResponsePayload;
  sdpUserAuth0TokenLogin: Auth0LoginPayload;
  sendProspectsToHubspot?: Maybe<ProspectExportPayload>;
  sendProspectsToOutreach: ProspectExportJobPayload;
  sendProspectsToSalesforce?: Maybe<ProspectExportPayload>;
  setPassword: ResponsePayload;
  testZoomInfoIntegration?: Maybe<ResponsePayload>;
  updateAicp: ResponsePayload;
  updateClientOrgOpportunities?: Maybe<ResponsePayload>;
  updateContractDetails: ResponsePayload;
  updateCustomExegraphicLens: ResponsePayload;
  updateHubspotConfiguration?: Maybe<ResponsePayload>;
  updateIcpCompaniesLookalikeApiResult?: Maybe<UpdateIcpCompaniesLookalikeApiResultPayload>;
  updateIcpScoreTuning: ResponsePayload;
  updateNotificationRule?: Maybe<NotificationRulePayload>;
  updatePerformanceReportData: UpdatePerformanceReportDataPayload;
  updateSalesforceConfiguration?: Maybe<ResponsePayload>;
  updateSalesforceSandboxConfiguration?: Maybe<ResponsePayload>;
  updateUser: ResponsePayload;
  updateUserClientOrganization: ResponsePayload;
  updateUserSettings: UpdateUserSettingsPayload;
  uploadAccountSuppressionList: UploadAccountSuppressionListPayload;
  userAcceptPrivacyPolicy: ResponsePayload;
  userLogout: ResponsePayload;
};


export type MutationAddAicpNoteArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<NoteContentType>;
  icpId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type MutationAddComparisonListArgs = {
  comparisonFile: FileInput;
  fileName: Scalars['String']['input'];
  icpId: Scalars['EntityId']['input'];
};


export type MutationAddCustomExegraphicLensArgs = {
  customExegraphicLensFile: FileInput;
  customExegraphicLensJsonFile?: InputMaybe<FileInput>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCloneSalesforceConfigurationArgs = {
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type MutationCreateClientOrganizationArgs = {
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  salesPersonName?: InputMaybe<Scalars['String']['input']>;
  salesforceAccountId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type MutationCreateCompanyUrlLookupArgs = {
  input: CompanyUrlLookupInput;
};


export type MutationCreateCustomLensArgs = {
  lensDescription?: InputMaybe<Scalars['String']['input']>;
  lensName: Scalars['String']['input'];
  logSource?: InputMaybe<LogSource>;
  logicalOperator: Scalars['String']['input'];
  signalQueries: Array<SignalQuery>;
};


export type MutationCreateIcpArgs = {
  accountSuppressionFile?: InputMaybe<FileInput>;
  assignedSdrUserIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  clientOrganizationId: Scalars['EntityId']['input'];
  exegraphicFilters?: InputMaybe<Array<InputMaybe<ExegraphicFilterInput>>>;
  headcountBins?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  headcountRange?: InputMaybe<IntRangeInput>;
  industries?: InputMaybe<Array<InputMaybe<IndustryInput>>>;
  jobFunctions?: InputMaybe<Array<InputMaybe<ContactJobFunction>>>;
  name: Scalars['String']['input'];
  revenueRange?: InputMaybe<IntRangeInput>;
  seedListFile: FileInput;
  seniorityLevels?: InputMaybe<Array<InputMaybe<ContactSeniorityLevel>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetListFile?: InputMaybe<FileInput>;
};


export type MutationCreateNewVersionIcpArgs = {
  aicpId: Scalars['EntityId']['input'];
};


export type MutationCreateNotificationRuleArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
  customExegraphicLensIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  exegraphicLensNames?: InputMaybe<Array<Scalars['String']['input']>>;
  icpsIds: Array<Scalars['EntityId']['input']>;
  name: Scalars['String']['input'];
  shouldReceiveCompanyUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  userIdsToNotify: Array<Scalars['EntityId']['input']>;
};


export type MutationCreateSalesForceIntegrationArgs = {
  input: CreateIntegrationInput;
};


export type MutationCreateSourceCompaniesListArgs = {
  name: Scalars['String']['input'];
  uploadedFile: FileInput;
};


export type MutationDeleteAllCachedSignalsArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type MutationDeleteCachedSignalArgs = {
  cachedSignalId: Scalars['EntityId']['input'];
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type MutationDeleteNotificationRuleArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
  notificationRuleId: Scalars['EntityId']['input'];
};


export type MutationDismissEntitiesToExportArgs = {
  entityExportId: Scalars['EntityId']['input'];
  entityToExportIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type MutationEditCustomSignalArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  lensName: Scalars['String']['input'];
  logicalOperator: Scalars['String']['input'];
  signalId: Scalars['EntityId']['input'];
  signalQueries: Array<SignalQuery>;
};


export type MutationEnableHubspotIntegrationArgs = {
  authorizationCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnableOutreachIntegrationArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type MutationEnableSalesforceIntegrationArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type MutationEnableSalesforceSandboxIntegrationArgs = {
  authorizationCode: Scalars['String']['input'];
};


export type MutationEnableZoomInfoIntegrationArgs = {
  clientId: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationGenerateCachedSignalArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
  signalDescriptionSearchText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGetProspectsForCsvExportArgs = {
  accountId: Scalars['EntityId']['input'];
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  prospectIds: Array<InputMaybe<Scalars['EntityId']['input']>>;
};


export type MutationHandleIcpCompaniesLookalikeApiResultErrorArgs = {
  asyncJobToken: Scalars['String']['input'];
};


export type MutationInviteUserToClientOrganizationArgs = {
  emailAddress: Scalars['String']['input'];
  fullName: Scalars['String']['input'];
};


export type MutationMatchPlatformAccountArgs = {
  platformAccountId: Scalars['EntityId']['input'];
  registeredDomain: Scalars['String']['input'];
};


export type MutationProcessAccountCsvExportRequestArgs = {
  customExegraphicLensesToExport?: InputMaybe<CustomExegraphicLensesToExportInput>;
  filters?: InputMaybe<ScoredAccountFiltersInput>;
  icpId: Scalars['EntityId']['input'];
  requestedAccountsCount: Scalars['Int']['input'];
  sortBy?: InputMaybe<AccountIcpScoreSortableFields>;
  sortDirection?: InputMaybe<SortDirection>;
};


export type MutationProcessAccountHubspotExportArgs = {
  aicpId: Scalars['EntityId']['input'];
  exportAction: ExportAction;
  filters?: InputMaybe<ScoredAccountFiltersInput>;
  requestedCompanyCount?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationProcessAccountSalesforceExportArgs = {
  aicpId: Scalars['EntityId']['input'];
  exportAction: ExportAction;
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  exportTargetType?: InputMaybe<ExportTargetType>;
  filters?: InputMaybe<ScoredAccountFiltersInput>;
  requestedCompanyCount?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationProcessCompanyCsvExportRequestArgs = {
  filters?: InputMaybe<CompanyFiltersInput>;
  requestedCompanyCount: Scalars['Int']['input'];
};


export type MutationProcessCompanyPlatformIntegrationExportArgs = {
  exportAction: ExportAction;
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  exportTargetType: ExportTargetType;
  filters?: InputMaybe<CompanyFiltersInput>;
  requestedCompanyCount: Scalars['Int']['input'];
};


export type MutationProcessIcpUpdatesAccountCsvExportRequestArgs = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  exegraphicLensNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  requestedCompanyCount: Scalars['Int']['input'];
  selectedAccountsIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type MutationProcessIcpUpdatesAccountSalesforceExportArgs = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  exegraphicLensNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  exportAction: ExportAction;
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  requestedCompanyCount: Scalars['Int']['input'];
  selectedAccountsIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type MutationRefreshAccessTokenArgs = {
  email: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterUploadedFileArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  url: Scalars['String']['input'];
};


export type MutationRemoveAccountSuppressionListFromIcpArgs = {
  accountSuppressionListId: Scalars['EntityId']['input'];
  icpId: Scalars['EntityId']['input'];
};


export type MutationRemoveComparisonListFromIcpArgs = {
  comparisonListId: Scalars['EntityId']['input'];
  icpId: Scalars['EntityId']['input'];
};


export type MutationRemoveCustomExegraphicLensArgs = {
  customExegraphicLensId: Scalars['EntityId']['input'];
};


export type MutationRemoveIcpArgs = {
  id: Scalars['EntityId']['input'];
};


export type MutationRemoveSalesForceIntegrationArgs = {
  input: ClientOrganizationInput;
};


export type MutationRemoveSalesforceConfigurationArgs = {
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type MutationRemoveUserArgs = {
  userId: Scalars['EntityId']['input'];
};


export type MutationRemoveUserFromClientOrgArgs = {
  userId: Scalars['EntityId']['input'];
};


export type MutationRequestAccountUpgradeArgs = {
  comments?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};


export type MutationRetryExportProcessArgs = {
  entityExportId: Scalars['EntityId']['input'];
  entityToExportIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type MutationSdpUserAuth0TokenLoginArgs = {
  auth0Id: Scalars['String']['input'];
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendProspectsToHubspotArgs = {
  accountId: Scalars['EntityId']['input'];
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  prospectIds: Array<InputMaybe<Scalars['EntityId']['input']>>;
};


export type MutationSendProspectsToOutreachArgs = {
  accountId: Scalars['EntityId']['input'];
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  mailboxId?: InputMaybe<Scalars['String']['input']>;
  outreachSequenceId?: InputMaybe<Scalars['String']['input']>;
  outreachUserId: Scalars['String']['input'];
  prospectIds: Array<InputMaybe<Scalars['EntityId']['input']>>;
};


export type MutationSendProspectsToSalesforceArgs = {
  accountId: Scalars['EntityId']['input'];
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  exportTargetType?: InputMaybe<ExportTargetType>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  prospectIds: Array<InputMaybe<Scalars['EntityId']['input']>>;
  salesforceUserId: Scalars['String']['input'];
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
  setPasswordVerificationTokenId: Scalars['EntityId']['input'];
  userId: Scalars['EntityId']['input'];
};


export type MutationTestZoomInfoIntegrationArgs = {
  clientId: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUpdateAicpArgs = {
  aicpId: Scalars['EntityId']['input'];
  assignedSdrUserIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  performanceReportConfig?: InputMaybe<PerformanceReportConfigInput>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationUpdateClientOrgOpportunitiesArgs = {
  endDate?: InputMaybe<Scalars['Datetime']['input']>;
  startDate?: InputMaybe<Scalars['Datetime']['input']>;
};


export type MutationUpdateContractDetailsArgs = {
  aicpLimit?: InputMaybe<Scalars['Int']['input']>;
  clientOrganizationId: Scalars['EntityId']['input'];
  companyDownloadLimit?: InputMaybe<Scalars['Int']['input']>;
  contextProfileAnswers?: InputMaybe<Array<InputMaybe<ContextProfileAnswerInput>>>;
  endDate?: InputMaybe<Scalars['Datetime']['input']>;
  isMfaEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isSsoEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  partnerDataSource?: InputMaybe<Array<InputMaybe<PartnerDataSource>>>;
  startDate?: InputMaybe<Scalars['Datetime']['input']>;
  userLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateCustomExegraphicLensArgs = {
  customExegraphicLensFile: FileInput;
  customExegraphicLensId: Scalars['EntityId']['input'];
  customExegraphicLensJsonFile?: InputMaybe<FileInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateHubspotConfigurationArgs = {
  hubspotObjectMappings: Array<InputMaybe<ObjectMappingInput>>;
};


export type MutationUpdateIcpCompaniesLookalikeApiResultArgs = {
  asyncJobToken: Scalars['String']['input'];
  icpCompaniesResult?: InputMaybe<Scalars['String']['input']>;
  icpCompaniesResultUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIcpScoreTuningArgs = {
  customExegraphicLensTunings?: InputMaybe<Array<InputMaybe<CustomExegraphicLensTuningInput>>>;
  exegraphicLensTunings?: InputMaybe<Array<InputMaybe<ExegraphicLensTuningInput>>>;
  icpId: Scalars['EntityId']['input'];
};


export type MutationUpdateNotificationRuleArgs = {
  customExegraphicLensIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  exegraphicLensNames?: InputMaybe<Array<Scalars['String']['input']>>;
  icpsIds: Array<Scalars['EntityId']['input']>;
  name: Scalars['String']['input'];
  notificationRuleId: Scalars['EntityId']['input'];
  shouldReceiveCompanyUpdates?: InputMaybe<Scalars['Boolean']['input']>;
  userIdsToNotify: Array<Scalars['EntityId']['input']>;
};


export type MutationUpdatePerformanceReportDataArgs = {
  icpId: Scalars['EntityId']['input'];
};


export type MutationUpdateSalesforceConfigurationArgs = {
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  profileName: Scalars['String']['input'];
  salesforceObjectMappings: Array<InputMaybe<SalesforceObjectMapping>>;
};


export type MutationUpdateSalesforceSandboxConfigurationArgs = {
  exportConfigId?: InputMaybe<Scalars['EntityId']['input']>;
  profileName: Scalars['String']['input'];
  salesforceObjectMappings: Array<InputMaybe<SalesforceObjectMapping>>;
};


export type MutationUpdateUserArgs = {
  clientOrganizationId?: InputMaybe<Scalars['EntityId']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['EntityId']['input'];
};


export type MutationUpdateUserClientOrganizationArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type MutationUpdateUserSettingsArgs = {
  geoSubdivision1Ids?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  metroAreaIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  userId: Scalars['EntityId']['input'];
};


export type MutationUploadAccountSuppressionListArgs = {
  accountSuppressionFile: FileInput;
  icpId: Scalars['EntityId']['input'];
};


export type MutationUserLogoutArgs = {
  unhashedLoginToken?: InputMaybe<Scalars['String']['input']>;
};

export type NewsSignalCategoriesPayload = {
  __typename?: 'NewsSignalCategoriesPayload';
  newsSignalCategories?: Maybe<Array<Maybe<NewsSignalCategory>>>;
  status: ResponseStatus;
};

export type NewsSignalCategory = {
  __typename?: 'NewsSignalCategory';
  description?: Maybe<Scalars['String']['output']>;
  friendlyDescription?: Maybe<Scalars['String']['output']>;
  friendlyName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['EntityId']['output']>;
};

export type Note = {
  __typename?: 'Note';
  aicpId?: Maybe<Scalars['EntityId']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentType?: Maybe<NoteContentType>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  id?: Maybe<Scalars['EntityId']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  updatedBy?: Maybe<User>;
};

export enum NoteContentType {
  Html = 'HTML',
  Json = 'JSON'
}

export type NotificationRule = {
  __typename?: 'NotificationRule';
  clientOrganization: ClientOrganization;
  createdAt: Scalars['Datetime']['output'];
  createdBy: User;
  customExegraphicLenses: Array<CustomExegraphicLens>;
  exegraphicLenses: Array<ExegraphicLens>;
  icps: Array<IdealCustomerProfile>;
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  shouldReceiveCompanyUpdates: Scalars['Boolean']['output'];
  status: NotificationRuleStatus;
  updatedAt: Scalars['Datetime']['output'];
  usersToNotify: Array<User>;
};

export type NotificationRulePayload = {
  __typename?: 'NotificationRulePayload';
  notificationRule?: Maybe<NotificationRule>;
  status?: Maybe<ResponseStatus>;
};

export enum NotificationRuleStatus {
  Active = 'ACTIVE',
  Removed = 'REMOVED'
}

export type Oauth2Config = {
  __typename?: 'Oauth2Config';
  authUrl?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  redirectUri?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
};

export type ObjectMappingInput = {
  entityName?: InputMaybe<ExportableEntityName>;
  fieldMappings: Array<InputMaybe<FieldMappingInput>>;
  platformObjectName?: InputMaybe<Scalars['String']['input']>;
};

export type OpenSearchIndexSignalComponent = {
  __typename?: 'OpenSearchIndexSignalComponent';
  companyCountEstimate?: Maybe<Scalars['Int']['output']>;
  dateWindow?: Maybe<DateWindow>;
  description: Scalars['String']['output'];
  index: CollectionType;
  logicalOperator: Scalars['String']['output'];
  maximumDocumentCount?: Maybe<Scalars['Int']['output']>;
  minimumDocumentCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  newsCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  newsDateSelection?: Maybe<DateWindow>;
  queryOptions?: Maybe<QueryOptions>;
  queryText: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  technologiesDetails?: Maybe<Array<Maybe<TechnologyDetails>>>;
};

export type OutreachClientPlatformIntegrationPayload = {
  __typename?: 'OutreachClientPlatformIntegrationPayload';
  outreachIntegration?: Maybe<OutreachIntegration>;
  status?: Maybe<ResponseStatus>;
};

export type OutreachIntegration = {
  __typename?: 'OutreachIntegration';
  mailboxes?: Maybe<Array<Maybe<OutreachMailbox>>>;
  outreachUsers?: Maybe<Array<Maybe<OutreachUser>>>;
  sequences?: Maybe<Array<Maybe<OutreachSequence>>>;
};


export type OutreachIntegrationSequencesArgs = {
  outreachUserId?: InputMaybe<Scalars['String']['input']>;
};

export type OutreachJob = {
  __typename?: 'OutreachJob';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['EntityId']['output'];
  result: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OutreachMailbox = {
  __typename?: 'OutreachMailbox';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type OutreachProspectExportJobStatusPayload = {
  __typename?: 'OutreachProspectExportJobStatusPayload';
  prospectErrors?: Maybe<Array<Maybe<ProspectError>>>;
  prospectExportJob?: Maybe<ProspectExportJob>;
  status?: Maybe<ResponseStatus>;
};

export type OutreachSequence = {
  __typename?: 'OutreachSequence';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OutreachUser = {
  __typename?: 'OutreachUser';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PartnerDataSource {
  Dnb = 'DNB',
  Zoominfo = 'ZOOMINFO'
}

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  accountsRenewed?: Maybe<Scalars['Int']['output']>;
  accountsWon?: Maybe<Scalars['Int']['output']>;
  aimTrackingHistory?: Maybe<Array<Maybe<AimTrackingHistoryEntry>>>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  dealsWonExegraphicCounts?: Maybe<Array<Maybe<PerformanceReportExegraphicCount>>>;
  dealsWonIndustryCounts?: Maybe<Array<Maybe<PerformanceReportIndustryCount>>>;
  platformIntegrationMetrics?: Maybe<PlatformIntegrationMetrics>;
  revenueMetrics?: Maybe<RevenueMetrics>;
};

export type PerformanceReportConfig = {
  __typename?: 'PerformanceReportConfig';
  aimCustomExegraphics?: Maybe<Array<Maybe<SelectedCustomExegraphicLens>>>;
  aimExegraphics?: Maybe<Array<Maybe<SelectedExegraphicLens>>>;
  icp?: Maybe<IdealCustomerProfile>;
  platformIntegrationSettings?: Maybe<Array<Maybe<PlatformIntegrationSettings>>>;
};

export type PerformanceReportConfigInput = {
  aimCustomExegraphics?: InputMaybe<Array<InputMaybe<SelectedCustomExegraphicLensInput>>>;
  aimExegraphics?: InputMaybe<Array<InputMaybe<SelectedExegraphicLensInput>>>;
  connectionType?: InputMaybe<ClientPlatformIntegrationConnectionType>;
  platformIntegrationSettings?: InputMaybe<Array<InputMaybe<PlatformIntegrationSettingsInput>>>;
};

export type PerformanceReportExegraphicCount = {
  __typename?: 'PerformanceReportExegraphicCount';
  count?: Maybe<Scalars['Int']['output']>;
  exegraphicDescription?: Maybe<Scalars['String']['output']>;
  exegraphicId?: Maybe<Scalars['EntityId']['output']>;
};

export type PerformanceReportIndustryCount = {
  __typename?: 'PerformanceReportIndustryCount';
  count?: Maybe<Scalars['Int']['output']>;
  industry?: Maybe<Industry>;
};

export type PlatformAccount = {
  __typename?: 'PlatformAccount';
  clientOrganizationId?: Maybe<Scalars['EntityId']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  id?: Maybe<Scalars['EntityId']['output']>;
  integrationImportEventId?: Maybe<Scalars['EntityId']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  platformId?: Maybe<Scalars['String']['output']>;
  platformType?: Maybe<ClientPlatformIntegrationConnectionType>;
  status?: Maybe<PlatformAccountStatus>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type PlatformAccountMatch = {
  __typename?: 'PlatformAccountMatch';
  clientOrganizationId?: Maybe<Scalars['EntityId']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  id?: Maybe<Scalars['EntityId']['output']>;
  isMatched?: Maybe<Scalars['Boolean']['output']>;
  platformAccount?: Maybe<PlatformAccount>;
  platformType?: Maybe<ClientPlatformIntegrationConnectionType>;
  registeredDomain?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  updatedBy?: Maybe<User>;
};

export type PlatformAccountMatchPayload = {
  __typename?: 'PlatformAccountMatchPayload';
  platformAccountMatch?: Maybe<PlatformAccountMatch>;
  status?: Maybe<ResponseStatus>;
};

export type PlatformAccountMatchesCountPayload = {
  __typename?: 'PlatformAccountMatchesCountPayload';
  platformAccountMatchesCount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ResponseStatus>;
};

export type PlatformAccountMatchesFiltersInput = {
  isExactSearch?: InputMaybe<Scalars['Boolean']['input']>;
  isMatched?: InputMaybe<Scalars['Boolean']['input']>;
  platformType: ClientPlatformIntegrationConnectionType;
  searchString?: InputMaybe<Scalars['String']['input']>;
};

export type PlatformAccountMatchesPayload = {
  __typename?: 'PlatformAccountMatchesPayload';
  platformAccountMatches?: Maybe<Array<Maybe<PlatformAccountMatch>>>;
  status?: Maybe<ResponseStatus>;
};

export enum PlatformAccountStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type PlatformAccountsPayload = {
  __typename?: 'PlatformAccountsPayload';
  platformAccounts?: Maybe<Array<Maybe<PlatformAccount>>>;
  status?: Maybe<ResponseStatus>;
};

export type PlatformIntegrationMetrics = {
  __typename?: 'PlatformIntegrationMetrics';
  accountStatusUpdatesCount?: Maybe<Scalars['Int']['output']>;
  averageTimeToClose?: Maybe<Scalars['Float']['output']>;
  opportunitiesInCrmCount?: Maybe<Scalars['Int']['output']>;
  totalAccountsCount?: Maybe<Scalars['Int']['output']>;
};

export type PlatformIntegrationSettings = {
  __typename?: 'PlatformIntegrationSettings';
  connectionType?: Maybe<ClientPlatformIntegrationConnectionType>;
  fieldMatchers?: Maybe<Array<Maybe<FieldMatcher>>>;
};

export type PlatformIntegrationSettingsInput = {
  platformObjectFieldExternalId?: InputMaybe<Scalars['String']['input']>;
  platformObjectName?: InputMaybe<SalesforceObjectName>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PlatformObject = {
  __typename?: 'PlatformObject';
  fields?: Maybe<Array<Maybe<PlatformObjectField>>>;
  name: Scalars['String']['output'];
};

export type PlatformObjectField = {
  __typename?: 'PlatformObjectField';
  dataType: DataType;
  externalId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<PlatformObjectFieldOption>>>;
};

export type PlatformObjectFieldOption = {
  __typename?: 'PlatformObjectFieldOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PlatformObjectMapping = {
  __typename?: 'PlatformObjectMapping';
  entityName?: Maybe<ExportableEntityName>;
  fieldMappings?: Maybe<Array<Maybe<SalesforceFieldMapping>>>;
  id?: Maybe<Scalars['EntityId']['output']>;
  salesforceObjectName?: Maybe<SalesforceObjectName>;
};

export type PostalCodePrefix3 = {
  __typename?: 'PostalCodePrefix3';
  code: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  subdivision1?: Maybe<GeoSubdivision1>;
};

export type Prospect = {
  __typename?: 'Prospect';
  enrichedContact?: Maybe<EnrichedContact>;
  id: Scalars['EntityId']['output'];
  recentEvents?: Maybe<Array<Maybe<ProspectEvent>>>;
  sampleContact?: Maybe<SampleContact>;
  source?: Maybe<ContactSource>;
};

export enum ProspectAction {
  Export = 'EXPORT'
}

export type ProspectError = {
  __typename?: 'ProspectError';
  error?: Maybe<Scalars['String']['output']>;
  prospectId?: Maybe<Scalars['EntityId']['output']>;
};

export type ProspectEvent = {
  __typename?: 'ProspectEvent';
  action: ProspectAction;
  createdAt: Scalars['Datetime']['output'];
  exportMethod: ExportMethod;
  icpsWithSuppressionOverrides?: Maybe<Array<Maybe<IdealCustomerProfile>>>;
  id: Scalars['EntityId']['output'];
  prospectId: Scalars['EntityId']['output'];
  user: User;
};

export type ProspectExport = {
  __typename?: 'ProspectExport';
  id: Scalars['EntityId']['output'];
  status?: Maybe<ExportStatus>;
};

export type ProspectExportJob = {
  __typename?: 'ProspectExportJob';
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  result?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ProspectExportJobPayload = {
  __typename?: 'ProspectExportJobPayload';
  prospectExportJob?: Maybe<ProspectExportJob>;
  status?: Maybe<ResponseStatus>;
};

export type ProspectExportPayload = {
  __typename?: 'ProspectExportPayload';
  prospectExport?: Maybe<ProspectExport>;
  status?: Maybe<ResponseStatus>;
};

export type ProspectFilter = {
  __typename?: 'ProspectFilter';
  jobFunctions?: Maybe<Array<Maybe<ContactJobFunction>>>;
  seniorityLevels?: Maybe<Array<Maybe<ContactSeniorityLevel>>>;
};

export type ProspectFilterOptionsPayload = {
  __typename?: 'ProspectFilterOptionsPayload';
  icpFilterOptions?: Maybe<Array<Maybe<DropdownOption>>>;
  starBandFilterOptions?: Maybe<Array<Maybe<DropdownOption>>>;
  status: ResponseStatus;
  targetListFilterOptions?: Maybe<Array<Maybe<DropdownOption>>>;
};

export type ProspectsForCsvExportPayload = {
  __typename?: 'ProspectsForCsvExportPayload';
  account?: Maybe<Account>;
  prospects?: Maybe<Array<Maybe<Prospect>>>;
  status: ResponseStatus;
};

export type Query = {
  __typename?: 'Query';
  SdpUserEntityExportStatuses: SdpUserEntityExportStatusesPayload;
  accountExegraphicChanges?: Maybe<AccountExegraphicChangesPayload>;
  accountExegraphicChangesTotal?: Maybe<AccountExegraphicChangesTotalPayload>;
  accountExegraphicCompaniesTotal?: Maybe<AccountExegraphicCompaniesTotalPayload>;
  activeAccountExportClientPlatformIntegrations: ClientPlatformIntegrationsPayload;
  activeAicpCount: ActiveAicpCountPayload;
  aicpCompanyDistributions: AicpCompanyDistributionsPayload;
  allUsers: UsersPayload;
  assignedAccountTotal?: Maybe<AssignedAccountTotalPayload>;
  assignedAccounts?: Maybe<AssignedAccountsPayload>;
  asyncOperation: AsyncOperationPayload;
  availableIntegrations: IntegrationsDeprecatedPayload;
  bullseyeVisualizationCompanies?: Maybe<BullseyeVisualizationCompaniesPayload>;
  categoryFieldOptions: CategoryFieldOptionsPayload;
  cities: CitiesPayload;
  clientIntegrations: ClientIntegrationsPayload;
  clientOrganization: ClientOrganizationPayload;
  clientOrganizationCachedSignals: ClientOrganizationCachedSignalsPayload;
  clientOrganizationContextProfile: ClientOrganizationContextProfilePayload;
  clientOrganizationSavedSignals: ClientOrganizationSavedSignalsPayload;
  clientOrganizations: ClientOrganizationsPayload;
  clientOrganizationsCountByNameMatch: ClientOrganizationsCountPayload;
  clientPlatformIntegrations: ClientPlatformIntegrationsPayload;
  companiesWithMatchingPlatformAccounts: CompaniesWithMatchingPlatformAccountsPayload;
  company: CompanyPayload;
  companyCsvExportStatus: CompanyCsvExportStatusPayload;
  companyCsvForExport: CompanyCsvForExportPayload;
  companyDownloadCount: CompanyDownloadCountPayload;
  companyExegraphicLenses: CompanyExegraphicLensesPayload;
  companyFilterOptions: CompanyFilterOptionsPayload;
  companyLookup: CompanyLookupPayload;
  companyLookupCount: CompanyLookupCountPayload;
  customExegraphicLens: CustomExegraphicLensPayload;
  customExegraphicLensUrls: CustomExegraphicLensUrlsPayload;
  dnbIndustries: DnbIndustriesPayload;
  entityExportFailures: EntityExportFailuresPayload;
  entityExportFailuresCount: EntityExportFailuresCountPayload;
  entityExportsHistory: SdpUserEntityExportPayload;
  entityExportsHistoryCount: SdpUserEntityExportCountsPayload;
  exegraphicLenses: ExegraphicLensPayload;
  exportableEntityFieldOptions: ExportableEntityFieldOptionsPayload;
  generateSignalName?: Maybe<GenerateSignalNamePayload>;
  geoSubdivision1: GeoSubdivision1Payload;
  geoSubdivision1s: GeoSubdivision1sPayload;
  geographicRegions: GeographicRegionsPayload;
  getAvailableIntegrations: IntegrationsPayload;
  getEnabledFeatureFlagsByApplication: FeatureFlagsPayload;
  getNotificationRule?: Maybe<NotificationRulePayload>;
  getOutreachClientPlatformIntegration: OutreachClientPlatformIntegrationPayload;
  getOutreachProspectExportJobStatus: OutreachProspectExportJobStatusPayload;
  getSalesforceProspectExportStatus: SalesforceProspectExportStatusPayload;
  healthCheck?: Maybe<ResponsePayload>;
  hubspotClientPlatformIntegration?: Maybe<HubspotClientPlatformIntegrationPayload>;
  hubspotProspectExportStatus: HubspotProspectExportStatusPayload;
  icpAccountCsvForExport: IcpCsvPayload;
  icpAccountIndustries: IcpAccountIndustriesPayload;
  icpFilterOptions: IcpFilterOptionsPayload;
  icpPerformanceReport: IcpPerformanceReportPayload;
  icpPerformanceReportConfig: IcpPerformanceReportConfigPayload;
  icpPerformanceReportDataUpdateStatus: IcpPerformanceReportDataUpdateStatus;
  icpScoreTuningConfig: IcpScoreTuningConfigPayload;
  idealCustomerProfile: IdealCustomerProfilePayload;
  idealCustomerProfiles: IdealCustomerProfilesPayload;
  idealCustomerProfilesByStatuses: IdealCustomerProfilesPayload;
  isIcpReadyForPerformanceReport: IsIcpReadyForPerformanceReportPayload;
  isOpenSearchSignalComponentQueryTextValid: IsOpenSearchSignalComponentQueryTextValidPayload;
  lensBuilderSelectSignalChatResponse?: Maybe<LensBuilderSelectSignalChatResponsePayload>;
  lensBuilderSignalChatResponse?: Maybe<LensBuilderSignalChatResponsePayload>;
  lensBuilderSignals?: Maybe<LensBuilderSignalsPayload>;
  llmGeneratedSignalDescription: LlmGeneratedSignalDescriptionPayload;
  llmGeneratedSignalDetails: LlmGeneratedSignalDetailsPayload;
  logSignalQueryDslUserAction?: Maybe<LogSignalQueryDslUserActionPayload>;
  metroAreas: MetroAreasPayload;
  newsSignalCategories: NewsSignalCategoriesPayload;
  platformAccountMatches: PlatformAccountMatchesPayload;
  platformAccountMatchesCount: PlatformAccountMatchesCountPayload;
  platformAccounts: PlatformAccountsPayload;
  prospectFilterOptions: ProspectFilterOptionsPayload;
  releaseNotes: ReleaseNotesPayload;
  restrictedCustomExegraphicLenses: RestrictedCustomExegraphicLensPayload;
  salesforceClientPlatformIntegration: SalesforceClientPlatformIntegrationPayload;
  salesforcePlatformEvent: SalesforcePlatformEventPayload;
  salesforceSandboxClientPlatformIntegration: SalesforceClientPlatformIntegrationPayload;
  sdpCustomersCount: SdpCustomerCountPayload;
  sdpUsers: SdpUsersPayload;
  signalComponentCompanyCountEstimate: SignalComponentCompanyCountEstimatePayload;
  signalComponentMetrics: SignalComponentMetricsPayload;
  signalComponentPreviewCompanies: SignalComponentPreviewCompaniesPayload;
  signalQuerySearchPreview?: Maybe<SignalQuerySearchPreviewPayload>;
  signalTechnologies: SignalTechnologiesPayload;
  user: UserPayload;
  userActiveAicpsCount: UserActiveAicpsCountPayload;
  userAicpsAccountCounts: UserAicpsAccountCountsPayload;
  validateSignalQueryDslString?: Maybe<ResponsePayload>;
};


export type QuerySdpUserEntityExportStatusesArgs = {
  entityExportIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryAccountExegraphicChangesArgs = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  exegraphicLensNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAccountExegraphicChangesTotalArgs = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  exegraphicLensNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryAccountExegraphicCompaniesTotalArgs = {
  customExegraphicLensIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  exegraphicLensNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryActiveAicpCountArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryAicpCompanyDistributionsArgs = {
  aicpId: Scalars['EntityId']['input'];
};


export type QueryAllUsersArgs = {
  query: UserQuery;
};


export type QueryAssignedAccountTotalArgs = {
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  starBands?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  targetListIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryAssignedAccountsArgs = {
  idealCustomerProfileIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  starBands?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  targetListIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryAsyncOperationArgs = {
  asyncOperationId: Scalars['String']['input'];
};


export type QueryBullseyeVisualizationCompaniesArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
};


export type QueryCategoryFieldOptionsArgs = {
  fieldCategories: Array<SalesforceObjectName>;
  integrationType: Scalars['String']['input'];
};


export type QueryCitiesArgs = {
  countryIsoCode?: InputMaybe<Scalars['String']['input']>;
  geoSubdivision1Id?: InputMaybe<Scalars['EntityId']['input']>;
};


export type QueryClientIntegrationsArgs = {
  input?: InputMaybe<ClientOrganizationInput>;
};


export type QueryClientOrganizationArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryClientOrganizationCachedSignalsArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryClientOrganizationContextProfileArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryClientOrganizationSavedSignalsArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryClientOrganizationsArgs = {
  filterNamesBy?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientOrganizationsCountByNameMatchArgs = {
  nameMatch?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompaniesWithMatchingPlatformAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  platformType?: InputMaybe<ClientPlatformIntegrationConnectionType>;
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  registeredDomain: Scalars['String']['input'];
};


export type QueryCompanyCsvForExportArgs = {
  companyCsvExportId: Scalars['EntityId']['input'];
};


export type QueryCompanyDownloadCountArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QueryCompanyLookupArgs = {
  filters?: InputMaybe<CompanyFiltersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  selectedCustomSignalIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  selectedSignalIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
};


export type QueryCompanyLookupCountArgs = {
  filters?: InputMaybe<CompanyFiltersInput>;
};


export type QueryCustomExegraphicLensArgs = {
  customExegraphicLensId: Scalars['EntityId']['input'];
};


export type QueryCustomExegraphicLensUrlsArgs = {
  customExegraphicLensId: Scalars['EntityId']['input'];
  fileType: Scalars['String']['input'];
};


export type QueryEntityExportFailuresArgs = {
  entityExportId: Scalars['EntityId']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryEntityExportFailuresCountArgs = {
  entityExportId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type QueryEntityExportsHistoryArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryGenerateSignalNameArgs = {
  messages?: InputMaybe<Array<InputMaybe<ChatMessage>>>;
  previousSuggestions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sessionId: Scalars['String']['input'];
  signalId: Scalars['String']['input'];
  signalQueryDslString: Scalars['String']['input'];
  signalType: CollectionType;
};


export type QueryGeoSubdivision1Args = {
  geoSubdivision1Id: Scalars['EntityId']['input'];
};


export type QueryGeoSubdivision1sArgs = {
  countryIsoCodes: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGeographicRegionsArgs = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type_?: InputMaybe<GeographicRegionType>;
};


export type QueryGetEnabledFeatureFlagsByApplicationArgs = {
  input: GetEnabledFeatureFlagsByApplicationInput;
};


export type QueryGetNotificationRuleArgs = {
  notificationRuleId: Scalars['EntityId']['input'];
};


export type QueryGetOutreachProspectExportJobStatusArgs = {
  prospectExportJobId: Scalars['EntityId']['input'];
};


export type QueryGetSalesforceProspectExportStatusArgs = {
  prospectExportId: Scalars['EntityId']['input'];
};


export type QueryHubspotProspectExportStatusArgs = {
  prospectExportId: Scalars['EntityId']['input'];
};


export type QueryIcpAccountCsvForExportArgs = {
  accountCsvExportId: Scalars['EntityId']['input'];
  idealCustomerProfileId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type QueryIcpAccountIndustriesArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
  industryType?: InputMaybe<IndustryType>;
};


export type QueryIcpPerformanceReportArgs = {
  endDate: Scalars['Datetime']['input'];
  idealCustomerProfileId: Scalars['EntityId']['input'];
  startDate: Scalars['Datetime']['input'];
};


export type QueryIcpPerformanceReportConfigArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
};


export type QueryIcpPerformanceReportDataUpdateStatusArgs = {
  eventId: Scalars['EntityId']['input'];
};


export type QueryIcpScoreTuningConfigArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
};


export type QueryIdealCustomerProfileArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
};


export type QueryIdealCustomerProfilesByStatusesArgs = {
  statuses: Array<IcpStatus>;
};


export type QueryIsIcpReadyForPerformanceReportArgs = {
  idealCustomerProfileId: Scalars['EntityId']['input'];
};


export type QueryIsOpenSearchSignalComponentQueryTextValidArgs = {
  queryText: Scalars['String']['input'];
  signalType: CollectionType;
};


export type QueryLensBuilderSelectSignalChatResponseArgs = {
  messages?: InputMaybe<Array<InputMaybe<ChatMessage>>>;
  sessionId: Scalars['String']['input'];
};


export type QueryLensBuilderSignalChatResponseArgs = {
  messages?: InputMaybe<Array<InputMaybe<ChatMessage>>>;
  previousQueryText?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
  signalId: Scalars['String']['input'];
  signalType: CollectionType;
};


export type QueryLlmGeneratedSignalDescriptionArgs = {
  index: CollectionType;
  previousDescription?: InputMaybe<Scalars['String']['input']>;
  previousQueryText?: InputMaybe<Scalars['String']['input']>;
  previousTitle?: InputMaybe<Scalars['String']['input']>;
  queryText: Scalars['String']['input'];
};


export type QueryLlmGeneratedSignalDetailsArgs = {
  userInput: Scalars['String']['input'];
};


export type QueryLogSignalQueryDslUserActionArgs = {
  dslString: Scalars['String']['input'];
  message: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
  signalId: Scalars['String']['input'];
  signalName?: InputMaybe<Scalars['String']['input']>;
  signalType: CollectionType;
  userAction: SignalBuilderUserAction;
};


export type QueryMetroAreasArgs = {
  geoSubdivision1Id: Scalars['EntityId']['input'];
};


export type QueryPlatformAccountMatchesArgs = {
  filters?: InputMaybe<PlatformAccountMatchesFiltersInput>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};


export type QueryPlatformAccountMatchesCountArgs = {
  filters?: InputMaybe<PlatformAccountMatchesFiltersInput>;
};


export type QueryPlatformAccountsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  platformType?: InputMaybe<ClientPlatformIntegrationConnectionType>;
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRestrictedCustomExegraphicLensesArgs = {
  clientOrganizationId: Scalars['EntityId']['input'];
};


export type QuerySdpCustomersCountArgs = {
  clientOrganizationId?: InputMaybe<Scalars['EntityId']['input']>;
};


export type QuerySignalComponentCompanyCountEstimateArgs = {
  dateWindow?: InputMaybe<DateWindow>;
  index: CollectionType;
  maximumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  minimumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  newsCategoriesInput?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsDateSelectionInput?: InputMaybe<DateWindow>;
  queryText: Scalars['String']['input'];
  technologiesInput?: InputMaybe<Array<InputMaybe<TechnologyDetailsInput>>>;
};


export type QuerySignalComponentMetricsArgs = {
  dateWindow?: InputMaybe<DateWindow>;
  index: CollectionType;
  maximumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  minimumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  newsCategoriesInput?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsDateSelectionInput?: InputMaybe<DateWindow>;
  queryText: Scalars['String']['input'];
  technologiesInput?: InputMaybe<Array<InputMaybe<TechnologyDetailsInput>>>;
};


export type QuerySignalComponentPreviewCompaniesArgs = {
  companyCount?: InputMaybe<Scalars['Int']['input']>;
  dateWindow?: InputMaybe<DateWindow>;
  index: CollectionType;
  maximumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  minimumDocumentCount?: InputMaybe<Scalars['Int']['input']>;
  newsCategoriesInput?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsDateSelectionInput?: InputMaybe<DateWindow>;
  queryText: Scalars['String']['input'];
  technologiesInput?: InputMaybe<Array<InputMaybe<TechnologyDetailsInput>>>;
};


export type QuerySignalQuerySearchPreviewArgs = {
  maximumMatchingDocuments?: InputMaybe<Scalars['Int']['input']>;
  minimumMatchingDocuments?: InputMaybe<Scalars['Int']['input']>;
  sessionId: Scalars['String']['input'];
  signalId: Scalars['String']['input'];
  signalQueryDslString: Scalars['String']['input'];
  signalType: CollectionType;
};


export type QuerySignalTechnologiesArgs = {
  searchString?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  userId: Scalars['EntityId']['input'];
};


export type QueryValidateSignalQueryDslStringArgs = {
  sessionId: Scalars['String']['input'];
  signalId: Scalars['String']['input'];
  signalQueryDslString: Scalars['String']['input'];
  signalType: CollectionType;
};

export type QueryOptions = {
  __typename?: 'QueryOptions';
  maximumDocumentCount?: Maybe<Scalars['Int']['output']>;
  minimumDocumentCount?: Maybe<Scalars['Int']['output']>;
};

export type QuestionAndAnswer = {
  __typename?: 'QuestionAndAnswer';
  answerText: Scalars['String']['output'];
  questionId: Scalars['EntityId']['output'];
  questionText: Scalars['String']['output'];
};

export type RangeOption = {
  __typename?: 'RangeOption';
  label?: Maybe<Scalars['String']['output']>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type RawSignal = {
  __typename?: 'RawSignal';
  aggregationMethod?: Maybe<AggregationMethod>;
  collection: CollectionType;
  dateWindow?: Maybe<DateWindow>;
  includeRecruiters?: Maybe<Scalars['Boolean']['output']>;
  maxAggScore?: Maybe<Scalars['Float']['output']>;
  minAggScore?: Maybe<Scalars['Float']['output']>;
  minSearchScore?: Maybe<Scalars['Float']['output']>;
  queryString: Scalars['String']['output'];
  signalName: Scalars['String']['output'];
};

export type RefreshAccessTokenPayload = {
  __typename?: 'RefreshAccessTokenPayload';
  accessToken?: Maybe<Scalars['String']['output']>;
  expiresIn?: Maybe<Scalars['Int']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  content: Scalars['String']['output'];
  publishedAt: Scalars['Datetime']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['Datetime']['output'];
  version: Scalars['String']['output'];
};

export type ReleaseNotesPayload = {
  __typename?: 'ReleaseNotesPayload';
  releaseNotes: Array<Maybe<ReleaseNote>>;
  status: ResponseStatus;
};

export type RemoveIcpPayload = {
  __typename?: 'RemoveIcpPayload';
  status: ResponseStatus;
};

export type ResponsePayload = {
  __typename?: 'ResponsePayload';
  status?: Maybe<ResponseStatus>;
};

export type ResponseStatus = {
  __typename?: 'ResponseStatus';
  error?: Maybe<Error>;
  success: Scalars['Boolean']['output'];
};

export type RestrictedCustomExegraphicLens = {
  __typename?: 'RestrictedCustomExegraphicLens';
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['EntityId']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<CustomExegraphicLensStatus>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type RestrictedCustomExegraphicLensPayload = {
  __typename?: 'RestrictedCustomExegraphicLensPayload';
  customExegraphicLenses?: Maybe<Array<Maybe<RestrictedCustomExegraphicLens>>>;
  status?: Maybe<ResponseStatus>;
};

export enum RevScoreBin {
  Four = 'FOUR',
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO',
  Zero = 'ZERO'
}

export type RevenueMetrics = {
  __typename?: 'RevenueMetrics';
  averageDealSize?: Maybe<Scalars['Float']['output']>;
  highestGrossing?: Maybe<Scalars['Float']['output']>;
  highestGrossingAccountName?: Maybe<Scalars['String']['output']>;
  lowestGrossing?: Maybe<Scalars['Float']['output']>;
  lowestGrossingAccountName?: Maybe<Scalars['String']['output']>;
  totalRevenueGenerated?: Maybe<Scalars['Float']['output']>;
};

export type Roles = {
  __typename?: 'Roles';
  account?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  admin?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  product?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SalesGeographicRegions = {
  __typename?: 'SalesGeographicRegions';
  metroAreas?: Maybe<Array<Maybe<MetroArea>>>;
  subdivision1s?: Maybe<Array<Maybe<GeoSubdivision1>>>;
};

export type SalesPerson = {
  __typename?: 'SalesPerson';
  name?: Maybe<Scalars['String']['output']>;
};

export type SalesforceClientPlatformIntegrationPayload = {
  __typename?: 'SalesforceClientPlatformIntegrationPayload';
  salesforceIntegration?: Maybe<SalesforceIntegration>;
  status?: Maybe<ResponseStatus>;
};

export type SalesforceExportConfig = {
  __typename?: 'SalesforceExportConfig';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['EntityId']['output']>;
  platformObjectMappings?: Maybe<Array<Maybe<PlatformObjectMapping>>>;
  profileName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type SalesforceExportObjectField = {
  __typename?: 'SalesforceExportObjectField';
  name?: Maybe<Scalars['String']['output']>;
};

export type SalesforceExportTarget = {
  __typename?: 'SalesforceExportTarget';
  entityType?: Maybe<ExportEntityType>;
  exportAction?: Maybe<ExportAction>;
};

export type SalesforceFieldMapping = {
  __typename?: 'SalesforceFieldMapping';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  internalField?: Maybe<Scalars['String']['output']>;
  salesforceObjectField?: Maybe<SalesforceExportObjectField>;
  staticValue?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

export type SalesforceFieldMappingInput = {
  internalField?: InputMaybe<Scalars['String']['input']>;
  salesforceField?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SalesforceIntegration = {
  __typename?: 'SalesforceIntegration';
  accountObjectFields?: Maybe<Array<Maybe<SalesforceObjectField>>>;
  contactObjectFields?: Maybe<Array<Maybe<SalesforceObjectField>>>;
  exportConfigs?: Maybe<Array<Maybe<SalesforceExportConfig>>>;
  isAccountExportConfigured?: Maybe<Scalars['Boolean']['output']>;
  leadObjectFields?: Maybe<Array<Maybe<SalesforceObjectField>>>;
  salesforceUsers?: Maybe<Array<Maybe<SalesforceUser>>>;
};

export enum SalesforceIntegrationImportEventStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type SalesforceObjectField = {
  __typename?: 'SalesforceObjectField';
  isPicklistType?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picklistValues?: Maybe<Array<Maybe<SalesforcePicklistValue>>>;
};

export type SalesforceObjectMapping = {
  entityName?: InputMaybe<ExportableEntityName>;
  fieldMappings: Array<InputMaybe<SalesforceFieldMappingInput>>;
  salesforceObjectName?: InputMaybe<SalesforceObjectName>;
};

export enum SalesforceObjectName {
  Account = 'ACCOUNT',
  Contact = 'CONTACT',
  Lead = 'LEAD',
  Opportunity = 'OPPORTUNITY'
}

export type SalesforcePicklistValue = {
  __typename?: 'SalesforcePicklistValue';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SalesforcePlatformEventPayload = {
  __typename?: 'SalesforcePlatformEventPayload';
  eventStatus?: Maybe<SalesforceIntegrationImportEventStatus>;
  status?: Maybe<ResponseStatus>;
};

export type SalesforceProspectExportJobStatusPayload = {
  __typename?: 'SalesforceProspectExportJobStatusPayload';
  prospectErrors?: Maybe<Array<Maybe<ProspectError>>>;
  prospectExportJob?: Maybe<ProspectExportJob>;
  status?: Maybe<ResponseStatus>;
};

export type SalesforceProspectExportStatusPayload = {
  __typename?: 'SalesforceProspectExportStatusPayload';
  prospectErrors?: Maybe<Array<Maybe<ProspectError>>>;
  prospectExport?: Maybe<ProspectExport>;
  status?: Maybe<ResponseStatus>;
};

export type SalesforceSandboxAccountExportTarget = {
  __typename?: 'SalesforceSandboxAccountExportTarget';
  entityType?: Maybe<ExportEntityType>;
  exportAction?: Maybe<ExportAction>;
};

export type SalesforceUser = {
  __typename?: 'SalesforceUser';
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
};

export type SampleContact = {
  __typename?: 'SampleContact';
  emailConfidence?: Maybe<EmailConfidence>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasEmail?: Maybe<Scalars['Boolean']['output']>;
  hasPhone?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['EntityId']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  linkedInUrl?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SavedSignal = {
  __typename?: 'SavedSignal';
  createdBy: User;
  description: Scalars['String']['output'];
  id: Scalars['EntityId']['output'];
  isMulticomponent?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  schemaFile?: Maybe<UploadedFile>;
  status: CustomExegraphicLensStatus;
  updatedAt: Scalars['Datetime']['output'];
  uploadedFile?: Maybe<UploadedFile>;
  uploadedJsonFile?: Maybe<UploadedFile>;
};

export type ScoredAccount = {
  __typename?: 'ScoredAccount';
  account?: Maybe<Account>;
  allComparisonLists?: Maybe<Array<Maybe<CsvCompanySource>>>;
  droppedReason?: Maybe<Scalars['String']['output']>;
  exportCounts?: Maybe<AccountIcpScoreExportCounts>;
  id: Scalars['EntityId']['output'];
  isSeed?: Maybe<Scalars['Boolean']['output']>;
  isSuppressionMatch?: Maybe<Scalars['Boolean']['output']>;
  previouslyExportedToCsv?: Maybe<Scalars['Boolean']['output']>;
  previouslyExportedToHubspot?: Maybe<Scalars['Boolean']['output']>;
  previouslyExportedToPlatforms?: Maybe<Array<Maybe<PreviouslyExported>>>;
  previouslyExportedToSalesforce?: Maybe<Scalars['Boolean']['output']>;
  previouslyExportedToSalesforceSandbox?: Maybe<Scalars['Boolean']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreDelta?: Maybe<Scalars['Float']['output']>;
  starScore?: Maybe<Scalars['Float']['output']>;
  targetSourceIds?: Maybe<Array<Maybe<Scalars['EntityId']['output']>>>;
  tunedScore?: Maybe<Scalars['Float']['output']>;
};

export type ScoredAccountFiltersInput = {
  comparisonListIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customExegraphicSignals?: InputMaybe<Array<InputMaybe<SelectedCustomExegraphicLensInput>>>;
  customSignalLinkOperator?: InputMaybe<FilterLinkOperator>;
  dnbHeadcount?: InputMaybe<IntRangeInput>;
  dnbIndustries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dnbRevenue?: InputMaybe<IntRangeInput>;
  exegraphicSignals?: InputMaybe<Array<InputMaybe<SelectedExegraphicLensInput>>>;
  exegraphicSignalsFilterOperator?: InputMaybe<FilterLinkOperator>;
  headcountBins?: InputMaybe<Array<InputMaybe<IntRangeInput>>>;
  includeComparison?: InputMaybe<IncludeAccountTypeFilterOptions>;
  includeDroppedUrls?: InputMaybe<Scalars['Boolean']['input']>;
  includeMappedSalesforceAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  includePreviouslyExportedToCsv?: InputMaybe<Scalars['Boolean']['input']>;
  includePreviouslyExportedToHubspot?: InputMaybe<Scalars['Boolean']['input']>;
  includePreviouslyExportedToSalesforce?: InputMaybe<Scalars['Boolean']['input']>;
  includePreviouslyExportedToSalesforceSandbox?: InputMaybe<Scalars['Boolean']['input']>;
  includeSuppressed?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  revScoreBins?: InputMaybe<Array<InputMaybe<RevScoreBin>>>;
  revenueBins?: InputMaybe<Array<InputMaybe<IntRangeInput>>>;
  seedsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  selectedScoredAccountIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  states?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tunedScoreBins?: InputMaybe<Array<InputMaybe<RevScoreBin>>>;
};

export type ScoredTargetCsvExport = {
  __typename?: 'ScoredTargetCsvExport';
  comparisonList?: Maybe<CompanySource>;
  scoredComparisonList?: Maybe<AccountCsvExport>;
};

export type SdpCustomerCountPayload = {
  __typename?: 'SdpCustomerCountPayload';
  count: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type SdpUserEntityExportCountsPayload = {
  __typename?: 'SdpUserEntityExportCountsPayload';
  entityExportsCount?: Maybe<Scalars['Int']['output']>;
  status: ResponseStatus;
};

export type SdpUserEntityExportPayload = {
  __typename?: 'SdpUserEntityExportPayload';
  entityExports?: Maybe<Array<Maybe<EntityExport>>>;
  status: ResponseStatus;
};

export type SdpUserEntityExportStatusesPayload = {
  __typename?: 'SdpUserEntityExportStatusesPayload';
  entityExports?: Maybe<Array<Maybe<EntityExport>>>;
  status: ResponseStatus;
};

export type SdpUsersPayload = {
  __typename?: 'SdpUsersPayload';
  status: ResponseStatus;
  users?: Maybe<Array<Maybe<User>>>;
};

export type SeedListAccountCsvExport = {
  __typename?: 'SeedListAccountCsvExport';
  storedResourceUrl?: Maybe<Scalars['String']['output']>;
};

export type SelectedCustomExegraphicLens = {
  __typename?: 'SelectedCustomExegraphicLens';
  id: Scalars['EntityId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value: ExegraphicLensValue;
};

export type SelectedCustomExegraphicLensInput = {
  id: Scalars['EntityId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  value: ExegraphicLensValue;
};

export type SelectedExegraphicLens = {
  __typename?: 'SelectedExegraphicLens';
  name: Scalars['String']['output'];
  value: ExegraphicLensValue;
};

export type SelectedExegraphicLensInput = {
  name: Scalars['String']['input'];
  value: ExegraphicLensValue;
};

export type Signal = {
  __typename?: 'Signal';
  conditions: Array<Maybe<SignalCondition>>;
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['EntityId']['output']>;
  name: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  type: SignalType;
};

export enum SignalBuilderUserAction {
  SignalComponentAdded = 'SIGNAL_COMPONENT_ADDED',
  SignalComponentEdited = 'SIGNAL_COMPONENT_EDITED',
  SignalComponentRemoved = 'SIGNAL_COMPONENT_REMOVED'
}

export type SignalComponentAudienceMetric = {
  __typename?: 'SignalComponentAudienceMetric';
  bucketId: Scalars['String']['output'];
  count: Scalars['Int']['output'];
};

export type SignalComponentCompanyCountEstimatePayload = {
  __typename?: 'SignalComponentCompanyCountEstimatePayload';
  countEstimate: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type SignalComponentMetricsPayload = {
  __typename?: 'SignalComponentMetricsPayload';
  countryMetrics?: Maybe<Array<Maybe<SignalComponentAudienceMetric>>>;
  headcountBinMetrics?: Maybe<Array<Maybe<SignalComponentAudienceMetric>>>;
  industryMetrics?: Maybe<Array<Maybe<SignalComponentAudienceMetric>>>;
  status: ResponseStatus;
};

export type SignalComponentPreviewCompaniesPayload = {
  __typename?: 'SignalComponentPreviewCompaniesPayload';
  companies?: Maybe<Array<Maybe<SignalComponentPreviewCompany>>>;
  status: ResponseStatus;
};

export type SignalComponentPreviewCompany = {
  __typename?: 'SignalComponentPreviewCompany';
  country?: Maybe<Scalars['String']['output']>;
  headcountBin?: Maybe<Scalars['String']['output']>;
  industry?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registeredDomain: Scalars['String']['output'];
  revenueBin?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  subIndustry?: Maybe<Scalars['String']['output']>;
};

export type SignalComponentUnion = OpenSearchIndexSignalComponent;

export type SignalCondition = {
  __typename?: 'SignalCondition';
  components: Array<Maybe<OpenSearchIndexSignalComponent>>;
  logicalOperator: Scalars['String']['output'];
};

export type SignalDsl = {
  __typename?: 'SignalDSL';
  exegraphicName: Scalars['String']['output'];
  lensAssembly: LensAssembly;
  rawSignals: Array<Maybe<RawSignal>>;
};

export type SignalQuery = {
  dateWindow?: InputMaybe<DateWindow>;
  maximumMatchingDocuments?: InputMaybe<Scalars['Int']['input']>;
  minimumMatchingDocuments?: InputMaybe<Scalars['Int']['input']>;
  newsCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  newsDateSelection?: InputMaybe<DateWindow>;
  searchResultSize?: InputMaybe<Scalars['Int']['input']>;
  sessionId?: InputMaybe<Scalars['String']['input']>;
  signalId?: InputMaybe<Scalars['String']['input']>;
  signalName?: InputMaybe<Scalars['String']['input']>;
  signalQueryDslString: Scalars['String']['input'];
  signalType: CollectionType;
  technologiesDetails?: InputMaybe<Array<InputMaybe<TechnologyDetailsInput>>>;
};

export type SignalQuerySearchPreview = {
  __typename?: 'SignalQuerySearchPreview';
  companyCount?: Maybe<Scalars['Int']['output']>;
  maximumMatchingDocuments?: Maybe<Scalars['Int']['output']>;
  minimumMatchingDocuments?: Maybe<Scalars['Int']['output']>;
  suggestedSearchTerms?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  topCompanyRegisteredDomains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type SignalQuerySearchPreviewPayload = {
  __typename?: 'SignalQuerySearchPreviewPayload';
  signalQuerySearchPreview?: Maybe<SignalQuerySearchPreview>;
  status?: Maybe<ResponseStatus>;
};

export type SignalTechnologiesPayload = {
  __typename?: 'SignalTechnologiesPayload';
  status: ResponseStatus;
  technologiesAndCategories?: Maybe<Array<Maybe<TechnologyDetails>>>;
};

export enum SignalType {
  CompanySummary = 'COMPANY_SUMMARY',
  Employee = 'EMPLOYEE',
  Firmographic = 'FIRMOGRAPHIC',
  JobPosting = 'JOB_POSTING',
  Mixed = 'MIXED',
  News = 'NEWS',
  Technographic = 'TECHNOGRAPHIC'
}

export type SnapshotDnbPartnerData = {
  __typename?: 'SnapshotDNBPartnerData';
  headcount?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  sicFourIndustry?: Maybe<Scalars['String']['output']>;
  sicTwoIndustry?: Maybe<Scalars['String']['output']>;
};

export type SnapshotSignal = {
  __typename?: 'SnapshotSignal';
  id?: Maybe<Scalars['EntityId']['output']>;
  value?: Maybe<ExegraphicLensValue>;
};

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SourceCompaniesList = {
  __typename?: 'SourceCompaniesList';
  id: Scalars['EntityId']['output'];
};

export type SourceCompaniesListPayload = {
  __typename?: 'SourceCompaniesListPayload';
  sourceCompaniesList: SourceCompaniesList;
  status: ResponseStatus;
};

export type StringOption = {
  __typename?: 'StringOption';
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum SuppressionListStatus {
  Active = 'ACTIVE',
  FailedToProcess = 'FAILED_TO_PROCESS',
  Processing = 'PROCESSING',
  Removed = 'REMOVED'
}

export type TargetBullseyeCompanies = {
  __typename?: 'TargetBullseyeCompanies';
  companies?: Maybe<Array<Maybe<BullseyeCompany>>>;
  targetSourceId: Scalars['EntityId']['output'];
  targetSourceName: Scalars['String']['output'];
};

export type TechnologyDetails = {
  __typename?: 'TechnologyDetails';
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCategory?: Maybe<Scalars['Boolean']['output']>;
  isTechnology?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  technologiesCount?: Maybe<Scalars['Int']['output']>;
};

export type TechnologyDetailsInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isCategory?: InputMaybe<Scalars['Boolean']['input']>;
  isTechnology?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  technologiesCount?: InputMaybe<Scalars['Int']['input']>;
};

export enum TuningEffect {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE'
}

export type UpdateIcpCompaniesLookalikeApiResultPayload = {
  __typename?: 'UpdateIcpCompaniesLookalikeApiResultPayload';
  status: ResponseStatus;
};

export type UpdatePerformanceReportDataPayload = {
  __typename?: 'UpdatePerformanceReportDataPayload';
  eventId?: Maybe<Scalars['EntityId']['output']>;
  status: ResponseStatus;
};

export type UpdateUserSettingsPayload = {
  __typename?: 'UpdateUserSettingsPayload';
  status: ResponseStatus;
};

export type UploadAccountSuppressionListPayload = {
  __typename?: 'UploadAccountSuppressionListPayload';
  accountSuppressionList?: Maybe<AccountSuppressionList>;
  idealCustomerProfile?: Maybe<IdealCustomerProfile>;
  status: ResponseStatus;
};

export type UploadedFile = {
  __typename?: 'UploadedFile';
  id?: Maybe<Scalars['EntityId']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  uploadedAt?: Maybe<Scalars['Datetime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UploadedFilePayload = {
  __typename?: 'UploadedFilePayload';
  status: ResponseStatus;
  uploadedFile: UploadedFile;
};

export type User = {
  __typename?: 'User';
  acceptedLatestPrivacyPolicy?: Maybe<Scalars['Boolean']['output']>;
  assignedAccount?: Maybe<AssignedAccount>;
  associatedAicps?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  clientOrganization?: Maybe<ClientOrganization>;
  companyName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  hasValidSuperAdminEmail?: Maybe<Scalars['Boolean']['output']>;
  hasVerifiedEmailAddress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['EntityId']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  isSuperuser?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  roles?: Maybe<Roles>;
  salesGeographicRegions?: Maybe<SalesGeographicRegions>;
};


export type UserAssignedAccountArgs = {
  accountId?: InputMaybe<Scalars['EntityId']['input']>;
};

export type UserActiveAicpsCountPayload = {
  __typename?: 'UserActiveAicpsCountPayload';
  count: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type UserAicpsAccountCountsPayload = {
  __typename?: 'UserAicpsAccountCountsPayload';
  aicpsAccountCounts?: Maybe<Array<Maybe<AicpAccountCount>>>;
  status: ResponseStatus;
};

export type UserInput = {
  emailAddress: Scalars['String']['input'];
  id?: InputMaybe<Scalars['EntityId']['input']>;
  name: Scalars['String']['input'];
};

export type UserPayload = {
  __typename?: 'UserPayload';
  status: ResponseStatus;
  user?: Maybe<User>;
};

export type UserQuery = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sortField?: InputMaybe<UserSortField>;
  text: Scalars['String']['input'];
};

export enum UserSortField {
  EmailAddress = 'EMAIL_ADDRESS',
  Name = 'NAME'
}

export type UsersPayload = {
  __typename?: 'UsersPayload';
  status: ResponseStatus;
  totalUserCount?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<Maybe<User>>>;
};

export type AccountExegraphicCompaniesTotalPayload = {
  __typename?: 'accountExegraphicCompaniesTotalPayload';
  accountExegraphicCompaniesTotal: Scalars['Int']['output'];
  status: ResponseStatus;
};

export type ExportableEntityFieldOptionsPayload = {
  __typename?: 'exportableEntityFieldOptionsPayload';
  activeIcpExportScoreOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  activeIcpExportTunedScoreOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  companyExportFieldOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  contactExportFieldOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  customLensExportFieldOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  exegraphicLensExportFieldOptions?: Maybe<Array<Maybe<ExportFieldOption>>>;
  status?: Maybe<ResponseStatus>;
};

export type GetEnabledFeatureFlagsByApplicationInput = {
  applicationName: Scalars['String']['input'];
};

export type PreviouslyExported = {
  __typename?: 'previouslyExported';
  isExported?: Maybe<Scalars['Boolean']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
};
